import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../../services/query";
import { ResponseFailureInfo } from "../../../types/common/common";

import {
  CHECK_DUPLICATE_PARTNER_PATH_PARAMS,
  CHECK_DUPLICATE_PARTNER_REQ,
  CHECK_DUPLICATE_PARTNER_RES,
  CREATE_PARTNER_REQ,
  CREATE_PARTNER_RES,
  DEACTIVATE_PARTNER_PATH_PARAMS,
  DEACTIVATE_PARTNER_REQ,
  DEACTIVATE_PARTNER_RES,
  DELETE_PARTNER_PATH_PARAMS,
  DELETE_PARTNER_REQ,
  DELETE_PARTNER_RES,
  GET_PARTNER_DETAIL_REQ,
  GET_PARTNER_DETAIL_RES,
  GET_PARTNER_LIST_REQ,
  GET_PARTNER_LIST_RES,
  UPDATE_PARTNER_MANAGER_REQ,
  UPDATE_PARTNER_MANAGER_RES,
  UPDATE_PARTNER_REQ,
  UPDATE_PARTNER_RES,
} from "../../../api-interfaces/shipda-api/admin/partnerManagement";

export const ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_PARTNER_MANAGEMENT_QUERY" }] as const,

  getPartnerList: (params: GET_PARTNER_LIST_REQ) =>
    [
      {
        ...params,
        ...ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN.all()[0],
        entity: "getPartnerList",
      },
    ] as const,

  getPartnerDetail: ({ id }: GET_PARTNER_DETAIL_REQ) =>
    [
      {
        id,
        ...ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN.all()[0],
        entity: "getPartnerDetail",
      },
    ] as const,
};

function useGetPartnerList(params: GET_PARTNER_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN.getPartnerList>,
    GET_PARTNER_LIST_RES
  >({
    queryKey: ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN.getPartnerList(params),
    requestOptions: {
      method: "get",
      path: `/fin-partner`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useGetPartnerDetail({
  id,
  onSuccess,
  enabled,
}: GET_PARTNER_DETAIL_REQ & {
  onSuccess?: (res: GET_PARTNER_DETAIL_RES) => void;
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN.getPartnerDetail>,
    GET_PARTNER_DETAIL_RES
  >({
    queryKey: ADMIN_PARTNER_MANAGEMENT_QUERY_KEY_GEN.getPartnerDetail({ id }),
    requestOptions: {
      method: "get",
      path: `/fin-partner/${id}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess,
    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useUpdatePartnerManager(id: number, onSuccess: () => void) {
  const mutation = useAppMutation<
    UPDATE_PARTNER_MANAGER_REQ,
    UPDATE_PARTNER_MANAGER_RES[]
  >({
    requestOptions: {
      method: "put",
      path: `/fin-partner/${id}/manager`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess,
  });

  return { ...mutation };
}

function useUpdatePartner(id: number, onSuccess: () => void) {
  const mutation = useAppMutation<
    Partial<UPDATE_PARTNER_REQ>,
    UPDATE_PARTNER_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/fin-partner/${id}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess,
  });

  return { ...mutation };
}

function useCreatePartner(onSuccess: () => void) {
  const mutation = useAppMutation<CREATE_PARTNER_REQ, CREATE_PARTNER_RES>({
    requestOptions: {
      method: "post",
      path: `/fin-partner`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess,
  });

  return { ...mutation };
}

function useDeletePartner({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: (err: ResponseFailureInfo | undefined) => void;
}) {
  const mutation = useAppMutation<
    DELETE_PARTNER_REQ,
    DELETE_PARTNER_RES,
    DELETE_PARTNER_PATH_PARAMS
  >({
    requestOptions: {
      method: "delete",
      path: ({ id }) => `/fin-partner/${id}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess,
    onError(err) {
      onError(err);
    },
  });

  return { ...mutation };
}

function useCheckDuplicatePartner() {
  const mutation = useAppMutation<
    CHECK_DUPLICATE_PARTNER_REQ,
    CHECK_DUPLICATE_PARTNER_RES,
    CHECK_DUPLICATE_PARTNER_PATH_PARAMS
  >({
    requestOptions: {
      method: "get",
      path: ({ target, value }) =>
        `/fin-partner/search/dup?target=${target}&value=${value}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useDeactivatePartner() {
  const mutation = useAppMutation<
    DEACTIVATE_PARTNER_REQ,
    DEACTIVATE_PARTNER_RES,
    DEACTIVATE_PARTNER_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ partnerId }) => `/fin-partner/${partnerId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

export const ADMIN_PARTNER_MANAGEMENT_QUERY = {
  useGetPartnerList,
  useGetPartnerDetail,
  useUpdatePartnerManager,
  useUpdatePartner,
  useCreatePartner,
  useDeletePartner,
  useCheckDuplicatePartner,
  useDeactivatePartner,
};

export default ADMIN_PARTNER_MANAGEMENT_QUERY;
