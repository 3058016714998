import { useAppMutation } from "@sellernote/_shared/src/services/query";

import { QueryResponseHandlerSuccessModalInfo } from "../../../components/QueryResponseHandler";

import {
  CANCEL_INVOICE_REQ,
  CANCEL_INVOICE_RES,
  CANCEL_WITHDRAW_REQ,
  CANCEL_WITHDRAW_RES,
  REJECT_SETTLEMENT_REQ,
  REJECT_SETTLEMENT_RES,
} from "../../../api-interfaces/shipda-api/admin/adminSlack";

function useRejectSettlement(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
  } | void
) {
  const { successModalInfo } = props || {};

  const mutation = useAppMutation<REJECT_SETTLEMENT_REQ, REJECT_SETTLEMENT_RES>(
    {
      requestOptions: {
        method: "post",
        path: `/slack/settlement/reject`,
        apiType: "ShipdaAdminDefaultNew",
      },
      successModalInfo,
    }
  );

  return { ...mutation };
}

function useCancelInvoice({
  successModalInfo,
  onSuccess,
}: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo;
  onSuccess?: () => void;
}) {
  const mutation = useAppMutation<CANCEL_INVOICE_REQ, CANCEL_INVOICE_RES>({
    requestOptions: {
      method: "post",
      path: `/slack/invoice/issue/cancel`,
      apiType: "ShipdaAdminDefaultNew",
    },

    successModalInfo,
  });

  onSuccess?.();

  return { ...mutation };
}

function useCancelWithdraw(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
  } | void
) {
  const { successModalInfo } = props || {};

  const mutation = useAppMutation<CANCEL_WITHDRAW_REQ, CANCEL_WITHDRAW_RES>({
    requestOptions: {
      method: "post",
      path: `/slack/withdraw/cancel`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,
  });

  return { ...mutation };
}

const ADMIN_SLACK_QUERY = {
  useRejectSettlement,
  useCancelInvoice,
  useCancelWithdraw,
};

export default ADMIN_SLACK_QUERY;
