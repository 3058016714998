export const RECEIVING_DATA_FOR_INSPECTION_MULTI_LOCATION = {
  receiving: {
    id: 70,
    receivingType: "twoDay",
    receivingKind: "domestic",
    status: "putAway",
    inspectionStatus: "normal",
    userId: 3161,
    quantity: 60,
    actualQty: 60,
    placeQty: null,
    isMatch: true,
    startInspectionAt: null,
    completeInspectionAt: "2021-08-31T05:05:48.000Z",
    agreedAt: null,
    bidId: null,
    memo: [{ memo: "요청사항", createdAt: "2021-08-31T04:14:43.673Z" }],
    dueDate: null,
    receivedAt: null,
    delivery: "parcel",
    receivingMethod: "direct",
    expectedDate: null,
    packageDetail: [
      {
        depth: 10,
        width: 10,
        height: 10,
        quantity: 1,
        weightKG: 10,
        packageType: "boxes",
        productName: "box",
      },
    ],
    requestAddress: null,
    detailAddress: null,
    invoiceNo: "s29337374748",
    isInvoiceMatched: false,
    driver: null,
    packageContainType: "basic",
    receivePackageType: "pallet",
    warehouseId: 1,
    workerList: [{ id: "7", name: "manager5" }],
    managerId: 7,
    completeAt: null,
    createdAt: "2021-08-31T04:14:43.680Z",
    updatedAt: "2021-10-15T04:59:07.758Z",
    deletedAt: null,
    user: {
      id: 3161,
      accountId: "c587a38c-ae4a-4d89-9ae4-3ce3fb4ad5cc",
      email: "jk.yang@ship-da.com",
      invoiceEmail: null,
      name: "양자경",
      lastName: null,
      engName: null,
      phone: "010514433321",
      type: "consignee",
      registerType: null,
      freightType: null,
      company: "셀러노트",
      engCompany: null,
      senderName: null,
      senderPhone: null,
      senderAddress: null,
      senderDetailAddress: null,
      senderPostCode: null,
      version: 2,
      country: "KR",
      forwardingManagerId: 55,
      fullfillmentManagerId: 7,
      fullfillmentSalesManagerId: 7,
      BRN: "1",
      isCertifiedBL: false,
      businessType: "person",
      comments: null,
      isDel: false,
      createdAt: "2021-07-14T05:52:48.000Z",
      updatedAt: "2022-03-16T07:16:42.000Z",
      deletedAt: null,
      lastLoginAt: "2022-04-01T00:25:31.000Z",
    },
    bid: null,
    items: [
      {
        id: 95,
        receivingId: 70,
        inspectionProblem: "none",
        receivingProblem: "none",
        inspectionProblemDirectInput: null,
        receivingProblemDirectInput: null,
        skuId: 8,
        inspectorId: 7,
        placerId: null,
        inspectItems: [
          {
            inspectingId: "2c5141f0-d652-4e11-ab0f-fd27ee6ae020",
            actualQty: 0,
            quantity: 1,
            inspectorId: null,
            isCompleteInspecting: false,
          },
        ],
        placeItems: [
          {
            placerId: 1,
            quantity: 1,
            placingId: "2c5141f0-d652-4e11-ab0f-fd27ee6ae020",
            isCompletePlacing: false,
          },
        ],
        quantity: 1,
        actualQty: 1,
        placeQty: null,
        faultyQty: 0,
        isMatch: null,
        startInspectionAt: null,
        completeInspectionAt: null,
        locationId: null,
        isCompleteInspection: true,
        isCompleteLoading: false,
        packageContainType: "basic",
        createdAt: "2021-08-31T02:30:54.236Z",
        updatedAt: "2022-02-25T02:40:12.000Z",
        deletedAt: null,
        sku: {
          id: 8,
          itemName: "모자",
          productCode: null,
          managementCode: "A123456",
          barCode: "B24823998822",
          userId: 3161,
          bidId: 1,
          category: "의류",
          buyerId: 1,
          buyingURL: "string",
          materialPackageType: "basic",
          returningCondition: "openNotUsed",
          createdAt: "2021-07-15T04:14:13.203Z",
          updatedAt: "2021-07-15T04:14:13.203Z",
          deletedAt: null,
          packages: [],
        },
      },
      {
        id: 96,
        receivingId: 70,
        inspectionProblem: "none",
        receivingProblem: "none",
        inspectionProblemDirectInput: null,
        receivingProblemDirectInput: null,
        skuId: 9,
        inspectorId: 7,
        placerId: null,
        inspectItems: [
          {
            inspectingId: "05dea7a5-ad10-4e54-9505-0569e1b8182e",
            actualQty: 0,
            quantity: 3,
            inspectorId: 7,
            isCompleteInspecting: false,
          },
          {
            inspectingId: "05dea125-ad10-4e54-9505-0569e1b8182e",
            actualQty: 3,
            quantity: 3,
            inspectorId: 7,
            isCompleteInspecting: true,
          },
        ],
        placeItems: [
          {
            quantity: 1,
            placingId: "05dea7a5-ad10-4e54-9505-0569e1b8182e",
            isCompletePlacing: false,
          },
        ],
        quantity: 13,
        actualQty: 1,
        placeQty: 0,
        faultyQty: 0,
        isMatch: null,
        startInspectionAt: null,
        completeInspectionAt: null,
        locationId: null,
        isCompleteInspection: true,
        isCompleteLoading: false,
        packageContainType: "basic",
        createdAt: "2021-08-31T02:30:54.240Z",
        updatedAt: "2022-03-31T08:56:00.000Z",
        deletedAt: null,
        sku: {
          id: 9,
          itemName: "신발",
          productCode: null,
          managementCode: "A654321",
          barCode: "B12333123312",
          userId: 3161,
          bidId: 1,
          category: "의류",
          buyerId: 1,
          buyingURL: "string",
          materialPackageType: "basic",
          returningCondition: "openNotUsed",
          createdAt: "2021-07-15T04:14:58.885Z",
          updatedAt: "2021-07-15T04:14:58.885Z",
          deletedAt: null,
          packages: [],
        },
      },
      {
        id: 94,
        receivingId: 70,
        inspectionProblem: "none",
        receivingProblem: "none",
        inspectionProblemDirectInput: null,
        receivingProblemDirectInput: null,
        skuId: 12,
        inspectorId: 7,
        placerId: null,
        inspectItems: [
          {
            inspectingId: "05dea7a5-ad10-4e54-9505-0569e1b8182e",
            actualQty: 0,
            quantity: 10,
            inspectorId: null,
            isCompleteInspecting: false,
          },
        ],
        placeItems: [
          {
            placerId: 7,
            quantity: 5,
            placingId: "05dea7a5-ad10-4e54-9505-0569e1b8182e",
            isCompletePlacing: false,
          },
        ],
        quantity: 10,
        actualQty: 10,
        placeQty: 0,
        faultyQty: 0,
        isMatch: null,
        startInspectionAt: null,
        completeInspectionAt: null,
        locationId: null,
        isCompleteInspection: true,
        isCompleteLoading: false,
        packageContainType: "basic",
        createdAt: "2021-08-26T06:36:41.333Z",
        updatedAt: "2022-04-01T01:24:36.000Z",
        deletedAt: null,
        sku: {
          id: 12,
          itemName: "스피커",
          productCode: null,
          managementCode: "",
          barCode: "123123123123",
          userId: 399,
          bidId: 0,
          category: null,
          buyerId: null,
          buyingURL: "",
          materialPackageType: "basic",
          returningCondition: "openNotUsed",
          createdAt: "2021-08-03T09:30:38.150Z",
          updatedAt: "2021-08-18T03:11:40.000Z",
          deletedAt: null,
          packages: [],
        },
      },
    ],
    manager: {
      id: 7,
      accountId: "test5",
      email: "manager5@email.com",
      name: "manager5",
      nameEN: "BaeJaeHo",
      officePhone: "02-3333-2222",
      fax: "0505-123-456",
      isSales: true,
      isOperator: true,
      authority: "manager",
      isActive: true,
      memo: "manager5's account",
      warehouseId: 1,
      OnOff: true,
      createdAt: "2021-08-10T09:25:24.919Z",
      updatedAt: "2021-11-05T01:03:51.879Z",
      deletedAt: null,
    },
    totalItem: { sku: 4, quantity: 22 },
  },
  attachment: [
    {
      id: 134,
      isBinded: true,
      actorId: 399,
      actorKind: "consignee",
      bucket: "boful-files-dev",
      key: "sku/7ffa08ba-cb1c-4bb9-9404-e2ebbae5788e.jpeg",
      name: "Sebastien-Riffault-Quarterons-Sancerre_300x300.jpeg",
      domain: "sku",
      targetId: 70,
      metadata: null,
      createdAt: "2021-09-10T11:33:38.739Z",
      updatedAt: "2021-09-10T11:33:39.000Z",
      deletedAt: null,
    },
    {
      id: 135,
      isBinded: true,
      actorId: 399,
      actorKind: "consignee",
      bucket: "boful-files-dev",
      key: "sku/ec3cb2be-a933-4359-b33f-0135b7e5ef27.jpeg",
      name: "sku_457fd552-ad82-45c0-9bc6-e09416f782d7.jpeg",
      domain: "sku",
      targetId: 70,
      metadata: null,
      createdAt: "2021-09-10T11:46:52.979Z",
      updatedAt: "2021-09-10T11:46:53.000Z",
      deletedAt: null,
    },
  ],
};

export const RECEIVING_DATA_FOR_SCANNING_MULTIPLE_INVOICES = {
  receiving: {
    id: 168,
    receivingType: "twoDay",
    receivingKind: "domestic",
    status: "beforeReceiving",
    inspectionStatus: "normal",
    userId: 3161,
    quantity: 22,
    actualQty: null,
    placeQty: null,
    isMatch: null,
    startInspectionAt: null,
    completeInspectionAt: null,
    agreedAt: null,
    bidId: null,
    memo: [{ memo: "국내택배", createdAt: "2021-10-14T06:16:34.284Z" }],
    dueDate: "2022-10-24T15:00:00.000Z",
    receivedAt: null,
    delivery: "parcel",
    receivingMethod: "direct",
    expectedDate: "2022-10-24T15:00:00.000Z",
    packageDetail: [],
    requestAddress: null,
    detailAddress: null,
    invoiceNo: [
      {
        parcelCompany: "cj",
        invoiceNo: "111-111",
      },
      {
        parcelCompany: "cj",
        invoiceNo: "222-222",
      },
      {
        parcelCompany: "cj",
        invoiceNo: "333-333",
      },
    ],
    isInvoiceMatched: false,
    driver: null,
    packageContainType: "basic",
    receivePackageType: "pallet",
    warehouseId: 1,
    workerList: [{ id: "7", name: "manager5" }],
    managerId: 7,
    completeAt: null,
    packingUnitPallet: null,
    packingUnitBox: null,
    packingUnitOther: null,
    createdAt: "2021-10-14T06:16:34.289Z",
    updatedAt: "2021-10-14T06:16:34.289Z",
    deletedAt: null,
    user: {
      id: 3161,
      accountId: "c587a38c-ae4a-4d89-9ae4-3ce3fb4ad5cc",
      email: "jk.yang@ship-da.com",
      invoiceEmail: null,
      name: "양자경",
      lastName: null,
      engName: null,
      phone: "010514433321",
      representativeName: null,
      address: null,
      type: "consignee",
      registerType: null,
      freightType: null,
      company: "셀러노트",
      engCompany: null,
      senderName: null,
      senderPhone: null,
      senderAddress: null,
      senderDetailAddress: null,
      senderPostCode: null,
      version: 2,
      country: "KR",
      forwardingManagerId: 55,
      fullfillmentManagerId: 7,
      fullfillmentSalesManagerId: 7,
      BRN: "1",
      businessType: "person",
      isConfirm: false,
      comments: null,
      appliedFulfillmentAt: null,
      createdAt: "2021-07-14T05:52:48.000Z",
      updatedAt: "2022-06-13T00:25:44.000Z",
      deletedAt: null,
      lastLoginAt: "2022-06-13T00:25:44.000Z",
    },
    bid: null,
    items: [
      {
        id: 429,
        receivingId: 168,
        inspectionProblem: "none",
        receivingProblem: "none",
        inspectionProblemDirectInput: null,
        receivingProblemDirectInput: null,
        skuId: 8,
        inspectorId: null,
        placerId: null,
        inspectItems: [
          {
            quantity: 11,
            inspectingId: "bd34820c-b3e6-11ec-9c6a-0a7159ab1988",
            isCompleteInspecting: false,
          },
        ],
        placeItems: [
          {
            placeQty: null,
            placerId: null,
            quantity: 11,
            placingId: "76c25397-46a2-11ec-9c6a-0a7159ab1988",
            locationId: null,
            isCompletePlacing: false,
          },
        ],
        quantity: 11,
        actualQty: null,
        placeQty: null,
        faultyQty: 0,
        isMatch: null,
        startInspectionAt: null,
        completeInspectionAt: null,
        locationId: null,
        isCompleteInspection: false,
        isCompleteLoading: false,
        packageContainType: "basic",
        basicQty: 11,
        mixQty: 0,
        createdAt: "2021-10-14T06:16:34.293Z",
        updatedAt: "2022-06-08T00:22:56.577Z",
        deletedAt: null,
        sku: {
          id: 8,
          itemName: "모자",
          productCode: null,
          managementCode: "A123456",
          barCode: "B24823998822",
          userId: 3161,
          bidId: 1,
          category: "의류",
          buyerId: 1,
          buyingURL: "string",
          materialPackageType: "basic",
          returningCondition: "openNotUsed",
          createdAt: "2021-07-15T04:14:13.203Z",
          updatedAt: "2021-07-15T04:14:13.203Z",
          deletedAt: null,
          packages: [],
        },
      },
      {
        id: 430,
        receivingId: 168,
        inspectionProblem: "none",
        receivingProblem: "none",
        inspectionProblemDirectInput: null,
        receivingProblemDirectInput: null,
        skuId: 9,
        inspectorId: null,
        placerId: null,
        inspectItems: [
          {
            quantity: 11,
            inspectingId: "bd3482f8-b3e6-11ec-9c6a-0a7159ab1988",
            isCompleteInspecting: false,
          },
        ],
        placeItems: [
          {
            placeQty: null,
            placerId: null,
            quantity: 11,
            placingId: "76c25449-46a2-11ec-9c6a-0a7159ab1988",
            locationId: null,
            isCompletePlacing: false,
          },
        ],
        quantity: 11,
        actualQty: null,
        placeQty: null,
        faultyQty: 0,
        isMatch: null,
        startInspectionAt: null,
        completeInspectionAt: null,
        locationId: null,
        isCompleteInspection: false,
        isCompleteLoading: false,
        packageContainType: "basic",
        basicQty: 11,
        mixQty: 0,
        createdAt: "2021-10-14T06:16:34.296Z",
        updatedAt: "2022-06-08T00:22:56.577Z",
        deletedAt: null,
        sku: {
          id: 9,
          itemName: "신발",
          productCode: null,
          managementCode: "A654321",
          barCode: "B12333123312",
          userId: 3161,
          bidId: 1,
          category: "의류",
          buyerId: 1,
          buyingURL: "string",
          materialPackageType: "basic",
          returningCondition: "openNotUsed",
          createdAt: "2021-07-15T04:14:58.885Z",
          updatedAt: "2021-07-15T04:14:58.885Z",
          deletedAt: null,
          packages: [],
        },
      },
    ],
    manager: {
      id: 7,
      accountId: "test5",
      email: "manager5@email.com",
      name: "manager5",
      nameEN: "BaeJaeHo",
      officePhone: "02-3333-2222",
      fax: "0505-123-456",
      isSales: true,
      isOperator: true,
      authority: "manager",
      isActive: true,
      memo: "manager5's account",
      warehouseId: 1,
      OnOff: true,
      createdAt: "2021-08-10T09:25:24.919Z",
      updatedAt: "2021-11-05T01:03:51.879Z",
      deletedAt: null,
    },
    totalItem: { sku: 2, quantity: 22 },
  },
  skuCount: 2,
  attachment: [
    {
      actorId: 1,
      actorKind: "warehouseManager",
      bucket: "boful-files-dev",
      createdAt: "2022-06-15T00:29:12.257Z",
      deletedAt: null,
      domain: "invoice",
      id: 1649,
      isBinded: true,
      key: "invoice/55b6ed78-4ae0-4c97-924f-59322f330b3c.png",
      metadata: null,
      name: "스크린샷 2022-01-10 오후 1.43.51.png",
      targetId: 257,
      updatedAt: "2022-06-15T00:29:12.000Z",
    },
    {
      id: 330,
      isBinded: true,
      actorId: 7,
      actorKind: "warehouseManager",
      bucket: "boful-files-dev",
      key: "packing/822ade89-751b-4166-8c5a-c1ae94eedbc8.jpeg",
      name: "smallBox.jpeg",
      domain: "packing",
      targetId: 168,
      metadata: null,
      createdAt: "2021-10-19T06:40:03.420Z",
      updatedAt: "2021-10-19T06:40:03.000Z",
      deletedAt: null,
    },
  ],
};
