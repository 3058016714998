import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../../services/query";

import {
  ADMIN_CANCEL_COUPON_REQ,
  ADMIN_CANCEL_COUPON_REQ_PATH_PARAMS,
  ADMIN_REGISTER_COUPON_REQ,
  ADMIN_REGISTER_COUPON_REQ_PATH_PARAMS,
  GET_ADMIN_COUPON_DETAIL_REQ,
  GET_ADMIN_COUPON_DETAIL_RES,
  GET_ADMIN_COUPON_LIST_REQ,
  GET_ADMIN_COUPON_LIST_RES,
  GET_ADMIN_USER_COUPON_LIST_REQ,
  GET_ADMIN_USER_COUPON_LIST_RES,
  ISSUE_COUPON_REQ,
  ISSUE_COUPON_RES,
} from "../../../api-interfaces/shipda-api/admin/adminCoupon";

export const ADMIN_COUPON_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_COUPON_QUERY_KEY_GEN" }] as const,

  getAdminCouponList: (params: GET_ADMIN_COUPON_LIST_REQ) =>
    [
      {
        ...ADMIN_COUPON_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminCouponList",
      },
    ] as const,

  getAdminCouponDetail: ({ couponId }: GET_ADMIN_COUPON_DETAIL_REQ) =>
    [
      {
        ...ADMIN_COUPON_QUERY_KEY_GEN.all()[0],
        couponId,
        entity: "getAdminCouponDetail",
      },
    ] as const,

  getAdminUserCouponList: (params: GET_ADMIN_USER_COUPON_LIST_REQ) =>
    [
      {
        ...ADMIN_COUPON_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminUserCouponList",
      },
    ] as const,
};

function useGetAdminCouponList(
  params: GET_ADMIN_COUPON_LIST_REQ,
  enabled: boolean
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_COUPON_QUERY_KEY_GEN.getAdminCouponList>,
    GET_ADMIN_COUPON_LIST_RES
  >({
    queryKey: ADMIN_COUPON_QUERY_KEY_GEN.getAdminCouponList(params),
    requestOptions: {
      method: "get",
      path: `/coupon/campaigns`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },

    keepPreviousData: true,
    enabled,
    failureModalInfo: {
      customizeMessage: () => ({
        title: "쿠폰 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useIssueCoupon() {
  const mutation = useAppMutation<ISSUE_COUPON_REQ, ISSUE_COUPON_RES>({
    requestOptions: {
      method: "post",
      path: `/coupon/issue`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetAdminCouponDetail({ couponId }: GET_ADMIN_COUPON_DETAIL_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_COUPON_QUERY_KEY_GEN.getAdminCouponDetail>,
    GET_ADMIN_COUPON_DETAIL_RES
  >({
    queryKey: ADMIN_COUPON_QUERY_KEY_GEN.getAdminCouponDetail({ couponId }),
    requestOptions: {
      method: "get",
      path: `/coupon/campaign/${couponId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useGetAdminUserCouponList(params: GET_ADMIN_USER_COUPON_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_COUPON_QUERY_KEY_GEN.getAdminUserCouponList>,
    GET_ADMIN_USER_COUPON_LIST_RES
  >({
    queryKey: ADMIN_COUPON_QUERY_KEY_GEN.getAdminUserCouponList(params),
    requestOptions: {
      method: "get",
      path: `/coupon`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useCancelCoupon() {
  const mutation = useAppMutation<
    ADMIN_CANCEL_COUPON_REQ,
    unknown,
    ADMIN_CANCEL_COUPON_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ shipmentId }) => `/coupon/cancel/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useRegisterCoupon() {
  const mutation = useAppMutation<
    ADMIN_REGISTER_COUPON_REQ,
    unknown,
    ADMIN_REGISTER_COUPON_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ shipmentId }) => `/coupon/use/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

const ADMIN_COUPON_QUERY = {
  useGetAdminCouponList,
  useIssueCoupon,
  useGetAdminCouponDetail,
  useGetAdminUserCouponList,
  useCancelCoupon,
  useRegisterCoupon,
};

export default ADMIN_COUPON_QUERY;
