import { useAppQueryWithQueryKeyFactory } from "../../../services/query";

import {
  GET_ADMIN_INLAND_AIR_FARES_REQ,
  GET_ADMIN_INLAND_AIR_FARES_RES,
  GET_ADMIN_INLAND_FCL_FARES_REQ,
  GET_ADMIN_INLAND_FCL_FARES_RES,
  GET_ADMIN_INLAND_LCL_FARES_REQ,
  GET_ADMIN_INLAND_LCL_FARES_RES,
} from "../../../api-interfaces/shipda-api/admin/adminInalndFares";

export const ADMIN_INLAND_FARES_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_INLAND_FARES_QUERY" }] as const,

  getAdminInlandFclFares: (params: GET_ADMIN_INLAND_FCL_FARES_REQ) =>
    [
      {
        ...ADMIN_INLAND_FARES_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminInlandFclFares",
      },
    ] as const,

  getAdminInlandLclFares: (params: GET_ADMIN_INLAND_LCL_FARES_REQ) =>
    [
      {
        ...ADMIN_INLAND_FARES_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminInlandLclFares",
      },
    ] as const,

  getAdminInlandAirFares: (params: GET_ADMIN_INLAND_AIR_FARES_REQ) =>
    [
      {
        ...ADMIN_INLAND_FARES_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminInlandAirFares",
      },
    ] as const,
};

function useGetAdminInlandFclFares(params: GET_ADMIN_INLAND_FCL_FARES_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_INLAND_FARES_QUERY_KEY_GEN.getAdminInlandFclFares>,
    GET_ADMIN_INLAND_FCL_FARES_RES
  >({
    queryKey: ADMIN_INLAND_FARES_QUERY_KEY_GEN.getAdminInlandFclFares(params),
    requestOptions: {
      method: "get",
      path: "/inlandFares/FCL",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },

    keepPreviousData: true,
  });

  return { ...queryResult };
}

function useGetAdminInlandLclFares(params: GET_ADMIN_INLAND_LCL_FARES_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_INLAND_FARES_QUERY_KEY_GEN.getAdminInlandLclFares>,
    GET_ADMIN_INLAND_LCL_FARES_RES
  >({
    queryKey: ADMIN_INLAND_FARES_QUERY_KEY_GEN.getAdminInlandLclFares(params),
    requestOptions: {
      method: "get",
      path: "/inlandFares/LCL",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },

    keepPreviousData: true,
  });

  return { ...queryResult };
}

function useGetAdminInlandAirFares(params: GET_ADMIN_INLAND_AIR_FARES_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_INLAND_FARES_QUERY_KEY_GEN.getAdminInlandAirFares>,
    GET_ADMIN_INLAND_AIR_FARES_RES
  >({
    queryKey: ADMIN_INLAND_FARES_QUERY_KEY_GEN.getAdminInlandAirFares(params),
    requestOptions: {
      method: "get",
      path: "/inlandFares/AIR",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },

    keepPreviousData: true,
  });

  return { ...queryResult };
}

const ADMIN_INLAND_FARES_QUERY = {
  useGetAdminInlandFclFares,
  useGetAdminInlandLclFares,
  useGetAdminInlandAirFares,
};

export default ADMIN_INLAND_FARES_QUERY;
