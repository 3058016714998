import {
  AIR_CAUTION_LIST_FOR_EXPORTATION,
  AIR_CAUTION_LIST_FOR_IMPORTATION,
  CONSOL_CAUTION_LIST,
  OCEAN_FCL_CAUTION_LIST_FOR_EXPORTATION,
  OCEAN_FCL_CAUTION_LIST_FOR_IMPORTATION,
  OCEAN_LCL_CAUTION_LIST_FOR_EXPORTATION,
  OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION,
  OCEAN_TICKET_CAUTION_LIST,
} from "@sellernote/_shared/src/constants/forwarding/quoteCautions";

import { ShipmentType } from "../../types/forwarding/common";
import { TransportCargoType } from "../../types/forwarding/quote";

/**
 * 쉽다, 어드민 공통 견적 주의사항 (참고문서: https://www.notion.so/shipda/PDF-93f383b506b945f18ef6069de06acc54?pvs=4)
 * (쉽다 웹의 견적의뢰 주의사항, 어드민 수동견적 코멘트 내 주의사항, 견적서 PDF 내 주의사항)
 * @returns {string[]} 견적 주의사항 리스트
 */
const getCautionI18nKeyListByTransportCargoType = ({
  shipmentType,
  transportCargoType,
  hasShipmentInfo,
  originInfo,
  destinationInfo,
  selectedSchedule,
  isOceanTicketRoute,
}: {
  shipmentType: ShipmentType;
  transportCargoType?: TransportCargoType | "Consol";
  hasShipmentInfo: boolean;
  originInfo?: { origin: { name?: string } };
  destinationInfo?: { name?: string; code?: string; country?: string };
  selectedSchedule: boolean;
  isOceanTicketRoute: boolean;
}) => {
  const inlandCostCaution = (() => {
    if (!hasShipmentInfo) return [];

    const allowsInlandCautionPort = (() => {
      if (shipmentType === "importation")
        return (
          destinationInfo?.name === "인천항" ||
          destinationInfo?.name === "부산항"
        );

      return (
        originInfo?.origin?.name === "인천항" ||
        originInfo?.origin?.name === "부산항"
      );
    })();

    if (!allowsInlandCautionPort) return [];

    // TODO: 내륙 운송료 계산 API or 코멘트 생성 API 개발 전 임시 코멘트 문구.
    return [
      "utils:getCautionListByTransportCargoType.인천/부산신항_접안_시_내륙운송료가_변동될_수_있습니다.",
    ];
  })();

  // 전체 공통 주의사항
  const generalCautionList = (() => {
    return selectedSchedule
      ? [
          "utils:getCautionListByTransportCargoType.안내드린_스케줄은_기상악화__천재지변_및_선사_항공사__등_사정에_따라_일정_변동이_있을_수_있습니다._납기가_촉박한_화물들은_항공운송_등의_대안루트를_이용할_수_있도록_메일_회신_또는_채팅_통한_연락_부탁드립니다.",
        ]
      : [
          "utils:getCautionListByTransportCargoType.납기가_촉박한_화물들은_항공운송_등의_대안루트를_이용할_수_있도록_메일_회신_또는_채팅_통한_연락_부탁드립니다.",
        ];
  })();

  // 수출 공통 주의사항
  const generalCautionOfExportationList = (() => {
    return [
      "utils:getCautionListByTransportCargoType.견적서_내_회색_음영처리_행의_항목들은_VAT가_별도_발생합니다.",
      "utils:getCautionListByTransportCargoType.일정_및_세부사항_변동에_따라_실제_청구되는_비용은_달라질_수_있습니다.",
    ];
  })();

  // 수출 시카고 관련 주의사항
  const chicagoCautionOfExportationList = (() => {
    // 시카고 철도역 여부
    const isDestinationOfChicagoRailCY =
      destinationInfo?.code === "USCHI" && destinationInfo.country === "US";

    if (
      shipmentType !== "exportation" ||
      !isDestinationOfChicagoRailCY ||
      transportCargoType !== "LCL"
    )
      return [];

    return [
      "utils:getCautionListByTransportCargoType.북미_내륙_철송_연계_운송되는_지역_Chicago_등_의_운임톤_기준은_1CBM___500kgs_기준_큰_값으로_적용됩니다.",
    ];
  })();

  switch (true) {
    case shipmentType === "importation" && isOceanTicketRoute:
      return [...OCEAN_TICKET_CAUTION_LIST, ...generalCautionList];

    case transportCargoType === "FCL":
      return shipmentType === "importation"
        ? [
            ...OCEAN_FCL_CAUTION_LIST_FOR_IMPORTATION,
            ...generalCautionList,
            ...inlandCostCaution,
          ]
        : [
            ...generalCautionOfExportationList,
            ...chicagoCautionOfExportationList,
            ...OCEAN_FCL_CAUTION_LIST_FOR_EXPORTATION,
            ...generalCautionList,
            ...inlandCostCaution,
          ];

    case transportCargoType === "AIR":
      return shipmentType === "importation"
        ? [...AIR_CAUTION_LIST_FOR_IMPORTATION, ...generalCautionList]
        : [
            ...generalCautionOfExportationList,
            ...chicagoCautionOfExportationList,
            ...AIR_CAUTION_LIST_FOR_EXPORTATION,
            ...generalCautionList,
          ];

    case transportCargoType === "LCL":
      return shipmentType === "importation"
        ? [...OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION, ...generalCautionList]
        : [
            ...generalCautionOfExportationList,
            ...chicagoCautionOfExportationList,
            ...OCEAN_LCL_CAUTION_LIST_FOR_EXPORTATION,
            ...generalCautionList,
          ];

    case transportCargoType === "Consol":
      return [...CONSOL_CAUTION_LIST, ...generalCautionList];

    default:
      return [];
  }
};

export { getCautionI18nKeyListByTransportCargoType };
