import { useAppQueryWithQueryKeyFactory } from "@sellernote/_shared/src/services/query";

import {
  GET_ADMIN_FORWARDERFARE_DETAIL_REQ,
  GET_ADMIN_FORWARDERFARE_LIST_REQ,
  GET_ADMIN_FORWARDERFARE_LIST_RES,
} from "../../../api-interfaces/shipda-api/admin/adminForwarderfare";

export const ADMIN_FORWARDERFARE_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_FORWARDERFARE_QUERY" }] as const,

  getAdminForwarderfareList: (params: GET_ADMIN_FORWARDERFARE_LIST_REQ) =>
    [
      {
        ...ADMIN_FORWARDERFARE_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminForwarderfareList",
      },
    ] as const,

  getAdminForwarderfareDetail: (params: GET_ADMIN_FORWARDERFARE_DETAIL_REQ) =>
    [
      {
        ...ADMIN_FORWARDERFARE_QUERY_KEY_GEN.all()[0],
        params,
        entity: "getAdminForwarderfareDetail",
      },
    ] as const,
};

function useGetAdminForwarderfare(params: GET_ADMIN_FORWARDERFARE_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof ADMIN_FORWARDERFARE_QUERY_KEY_GEN.getAdminForwarderfareList
    >,
    GET_ADMIN_FORWARDERFARE_LIST_RES
  >({
    queryKey:
      ADMIN_FORWARDERFARE_QUERY_KEY_GEN.getAdminForwarderfareList(params),
    requestOptions: {
      method: "get",
      path: `/forwarderfare/${params.freightType}`,
      params,
      apiType: "ShipdaAdminDefaultNew",
    },
    onError: () => console.log("오류가 발생했습니다."),
  });

  return {
    ...queryResult,
  };
}

// function useGetAdminForwarderfareDetail(
//   params: GET_ADMIN_FORWARDERFARE_DETAIL_REQ
// ) {
//   const queryResult = useAppQueryWithQueryKeyFactory<
//     ReturnType<
//       typeof ADMIN_FORWARDERFARE_QUERY_KEY_GEN.getAdminForwarderfareDetail
//     >,
//     GET_ADMIN_FORWARDERFARE_DETAIL_RES
//   >({
//     queryKey:
//       ADMIN_FORWARDERFARE_QUERY_KEY_GEN.getAdminForwarderfareDetail(params),
//     requestOptions: {
//       method: "get",
//       path: `auth`,
//       apiType: "ShipdaAdminDefaultNew",
//     },
//   });

//   return { ...queryResult };
// }

const ADMIN_FORWARDERFARE_QUERY = {
  useGetAdminForwarderfare,
  // useGetAdminForwarderfareDetail,
};
export default ADMIN_FORWARDERFARE_QUERY;
