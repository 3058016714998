import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../../services/query";

import {
  DELETE_FAVORITE_TEMPLATE_REQ,
  DELETE_FAVORITE_TEMPLATE_REQ_PATH_PARAMS,
  DELETE_FAVORITE_TEMPLATE_RES,
  DELETE_TEMPLATE_REQ,
  DELETE_TEMPLATE_REQ_PATH_PARAMS,
  DELETE_TEMPLATE_RES,
  GET_ADMIN_TEMPLATE_DETAIL_REQ,
  GET_ADMIN_TEMPLATE_DETAIL_REQ_PATH_PARAMS,
  GET_ADMIN_TEMPLATE_DETAIL_RES,
  GET_ADMIN_TEMPLATE_LIST_REQ,
  GET_ADMIN_TEMPLATE_LIST_RES,
  REGISTER_FAVORITE_TEMPLATE_REQ,
  REGISTER_FAVORITE_TEMPLATE_RES,
  SAVE_ADMIN_BID_TEMPLATE_REQ,
  SAVE_ADMIN_BID_TEMPLATE_RES,
} from "../../../api-interfaces/shipda-api/admin/adminTemplate";

export const ADMIN_TEMPLATE_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_TEMPLATE_QUERY" }] as const,

  getAdminBidTemplates: (params: GET_ADMIN_TEMPLATE_LIST_REQ) =>
    [
      {
        ...ADMIN_TEMPLATE_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminBidTemplates",
      },
    ] as const,

  getAdminBidTemplateDetail: (params: GET_ADMIN_TEMPLATE_DETAIL_REQ) =>
    [
      {
        ...ADMIN_TEMPLATE_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminBidTemplates",
      },
    ] as const,
};

function useGetAdminBidTemplates({
  enabled,
  ...params
}: GET_ADMIN_TEMPLATE_LIST_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_TEMPLATE_QUERY_KEY_GEN.getAdminBidTemplates>,
    GET_ADMIN_TEMPLATE_LIST_RES
  >({
    queryKey: ADMIN_TEMPLATE_QUERY_KEY_GEN.getAdminBidTemplates(params),
    requestOptions: {
      method: "get",
      path: `/template`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    enabled,
  });

  return { ...queryResult };
}

function useRegisterFavoriteTemplate() {
  const mutation = useAppMutation<
    REGISTER_FAVORITE_TEMPLATE_REQ,
    REGISTER_FAVORITE_TEMPLATE_RES
  >({
    requestOptions: {
      method: "post",
      path: "/template/favorite",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useDeleteFavoriteTemplate() {
  const mutation = useAppMutation<
    DELETE_FAVORITE_TEMPLATE_REQ,
    DELETE_FAVORITE_TEMPLATE_RES,
    DELETE_FAVORITE_TEMPLATE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "delete",
      path: ({ favoriteId }) => `template/favorite/${favoriteId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useDeleteTemplate() {
  const mutation = useAppMutation<
    DELETE_TEMPLATE_REQ,
    DELETE_TEMPLATE_RES,
    DELETE_TEMPLATE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "delete",
      path: ({ templateId }) => `template/${templateId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetAdminBidTemplateDetail() {
  const mutation = useAppMutation<
    GET_ADMIN_TEMPLATE_DETAIL_REQ,
    GET_ADMIN_TEMPLATE_DETAIL_RES,
    GET_ADMIN_TEMPLATE_DETAIL_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "get",
      path: ({ templateId }) => `template/${templateId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useSaveAdminBidTemplate() {
  const mutation = useAppMutation<
    SAVE_ADMIN_BID_TEMPLATE_REQ,
    SAVE_ADMIN_BID_TEMPLATE_RES
  >({
    requestOptions: {
      method: "post",
      path: "/template",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}
const ADMIN_TEMPLATE_QUERY = {
  useGetAdminBidTemplates,
  useRegisterFavoriteTemplate,
  useDeleteFavoriteTemplate,
  useDeleteTemplate,
  useGetAdminBidTemplateDetail,
  useSaveAdminBidTemplate,
};

export default ADMIN_TEMPLATE_QUERY;
