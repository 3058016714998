import { TFunction } from "i18next";

import { FreeTimeTableError } from "../../../api-interfaces/shipda-api/freeTime";
import { APP_NAME } from "../../../constants";
import { ShipdaCurrentLanguage } from "../../../i18n/i18nForShipda";

/** 어드민에는 표시가 불필요한 쉽다 웹 전용 안내 메세지 */
const getShipdaMessage = (t: TFunction) =>
  APP_NAME === "shipda-kr"
    ? t("utils:getFreeTimeErrorCodeToMessage.운영매니저에게_문의해주세요.")
    : "";

/** 쉽다 웹, 어드민 공통 에러코드
 * (failureInfo 내 error 로 에러메시지를 보내주는 것으로 변경되어 기본 메시지만 남겨둠)
 */
const getFreeTimeErrorCodeToMessage = ({
  errorCode,
  error,
  t,
}: {
  errorCode?: string;
  error?: string | FreeTimeTableError;
  t: TFunction;
}) => {
  const { liner, terminal } = error as FreeTimeTableError;

  const linerAndTerminal = (() => {
    if (liner && terminal)
      return `${
        ShipdaCurrentLanguage.currentLanguage === "ko"
          ? liner.name ?? liner.nameEN
          : liner.nameEN
      }, ${
        ShipdaCurrentLanguage.currentLanguage === "ko"
          ? terminal.nameKR ?? terminal.nameEN
          : terminal.nameEN
      }`;

    if (liner)
      return `${
        ShipdaCurrentLanguage.currentLanguage === "ko"
          ? liner.name ?? liner.nameEN
          : liner.nameEN
      }`;

    if (terminal)
      return `${
        ShipdaCurrentLanguage.currentLanguage === "ko"
          ? terminal.nameKR ?? terminal.nameEN
          : terminal.nameEN
      }`;

    return "";
  })();

  switch (errorCode) {
    case "E1000":
      return t("utils:getFreeTimeErrorCodeToMessage.지원하지_않는_선사입니다.");
    case "E1001":
      return t(
        "utils:getFreeTimeErrorCodeToMessage.도착터미널을_식별하지_못했습니다.___shipdaMessage__",
        {
          shipdaMessage: getShipdaMessage(t),
        }
      );
    case "E1002":
      return t(
        "utils:getFreeTimeErrorCodeToMessage.아직_도착터미널을_식별하지_못했습니다._입항완료_이후에_다시_시도해주세요."
      );
    case "E1003":
      return t(
        "utils:getFreeTimeErrorCodeToMessage.선사를_식별하지_못했습니다.___shipdaMessage__",
        { shipdaMessage: getShipdaMessage(t) }
      );
    case "E1004":
      return t(
        "utils:getFreeTimeErrorCodeToMessage.아직_선사를_식별하지_못했습니다._출항완료_이후에_다시_시도해주세요."
      );
    case "E1005":
      return t(
        "utils:getFreeTimeErrorCodeToMessage.수입_C조건의_운송은_현재_프리타임을_제공할_수_없습니다._입항완료_이후에_운영매니저에게_문의해주세요."
      );
    case "E1006":
      return t(
        "utils:getFreeTimeErrorCodeToMessage.컨테이너_데이터_확보에_실패했습니다.___shipdaMessage__",
        { shipdaMessage: getShipdaMessage(t) }
      );
    case "E1020":
      return t(
        "utils:getFreeTimeErrorCodeToMessage.본_운송의___linerAndTerminal__의_프리타임_부여정책이_확인되지_않습니다.___shipdaMessage__",
        {
          linerAndTerminal,
          shipdaMessage: getShipdaMessage(t),
        }
      );
    case "E1021":
      return t(
        "utils:getFreeTimeErrorCodeToMessage.본_운송의___linerAndTerminal__의_요금_정책이_확인되지_않습니다.___shipdaMessage__",
        {
          linerAndTerminal,
          shipdaMessage: getShipdaMessage(t),
        }
      );
    default:
      return t(
        "utils:getFreeTimeErrorCodeToMessage.프리타임_조회_중_오류가_발생했습니다.___shipdaMessage__",
        { shipdaMessage: getShipdaMessage(t) }
      );
  }
};

export { getFreeTimeErrorCodeToMessage };
