import { useAppQueryWithQueryKeyFactory } from "../../../services/query";

import { GET_SHIP_LIST_RES } from "../../../api-interfaces/shipda-api/admin/ship";

export const SHIP_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/SHIP_QUERY" }] as const,

  getShipList: () =>
    [
      {
        ...SHIP_QUERY_KEY_GEN.all()[0],
        entity: "shipList",
      },
    ] as const,
};

function useGetShipList() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIP_QUERY_KEY_GEN.getShipList>,
    GET_SHIP_LIST_RES
  >({
    queryKey: SHIP_QUERY_KEY_GEN.getShipList(),
    requestOptions: {
      method: "get",
      path: "/ships",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

const SHIP_QUERY = { useGetShipList };

export default SHIP_QUERY;
