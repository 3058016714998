const ADMIN_RETURNING_LIST = {
  list: [
    {
      id: 275,
      status: "beforeReturning",
      inspectionStatus: "normal",
      deliveringStatus: "notSent",
      userId: 399,
      productGroupIds: [{ qty: 1, productGroupId: 3000001 }],
      managerId: null,
      workerList: [],
      returningId: null,
      quantity: 7,
      delivery: "parcel",
      productDetail: "",
      returningPhotoKind: "truckNum",
      uri: "",
      isMatch: null,
      actualQty: 0,
      placeQty: null,
      startInspectionAt: null,
      completeInspectionAt: null,
      agreedAt: null,
      shippingId: 3140,
      dueDate: null,
      expectedDate: null,
      receivedAt: null,
      pickupDate: null,
      applicantName: "파이리",
      mobile: "456456456456",
      pickupAddress: "서울 종로구 청와대로 73 (팔판동)",
      detailAddress: "11-11",
      postalCode: "03054",
      requests: "",
      parcelCompany: "cj",
      invoiceNo: "654654654654",
      dispatchDate: null,
      isInvoiceMatched: false,
      isAlreadyApplied: false,
      registerCount: 0,
      driver: null,
      warehouseId: 1,
      completeAt: null,
      processStatus: null,
      createdAt: "2022-09-08T00:58:34.713Z",
      updatedAt: "2022-09-15T00:42:17.092Z",
      deletedAt: null,
      user: {
        id: 399,
        accountId: "youtube1",
        email: "qus@ship-da.com",
        invoiceEmail: null,
        name: "aaa",
        lastName: null,
        engName: null,
        phone: "01011111111",
        representativeName: "232",
        address: "232",
        type: "consignee",
        registerType: null,
        freightType: null,
        company: "수입하기정말쉽다",
        engCompany: "K",
        senderName: null,
        senderPhone: null,
        senderAddress: null,
        senderDetailAddress: null,
        senderPostCode: null,
        version: 1,
        country: "KR",
        forwardingManagerId: 55,
        fullfillmentManagerId: 11,
        fullfillmentSalesManagerId: 7,
        BRN: "1112223333",
        businessType: "person",
        isConfirm: false,
        comments: null,
        appliedFulfillmentAt: null,
        bizClass: null,
        bizType: null,
        createdAt: "2020-02-07T05:54:15.000Z",
        updatedAt: "2022-09-14T07:15:05.000Z",
        deletedAt: null,
        lastLoginAt: "2022-09-14T07:15:05.000Z",
      },
      warehouse: {
        id: 1,
        name: "인천1센터",
        address: "인천광역시 인천읍",
        detailAddress: "인천군",
        postalCode: "22222",
        phoneNumber: "032-771-2222",
        createdAt: "2021-05-13T00:56:47.383Z",
        updatedAt: "2022-08-26T05:25:21.108Z",
        deletedAt: null,
      },
      items: [
        {
          id: 1345,
          skuId: 665,
          quantity: 0,
          actualQty: 0,
          placeQty: null,
          inspectorId: null,
          placerId: null,
          placeItems: [
            {
              quantity: 0,
              placingId: "6c9bf091-4894-4916-894d-a9a6b9a4e012",
              isCompletePlacing: false,
            },
          ],
          faultyQty: 0,
          inspectionProblem: "none",
          returningProblem: "none",
          inspectionProblemDirectInput: null,
          returningProblemDirectInput: null,
          isComplete: false,
          isCompleteInspection: false,
          isCompleteLoading: false,
          area: null,
          locationId: null,
          createdAt: "2022-09-08T00:58:34.914Z",
          updatedAt: "2022-09-08T00:58:34.914Z",
          deletedAt: null,
          sku: {
            id: 665,
            itemName: "강아지 산책용 우비 레인코트 - 네이비 / XL",
            productCode: null,
            managementCode: null,
            barCode: null,
            userId: 399,
            bidId: null,
            category: null,
            buyerId: null,
            buyingURL: null,
            isHeteromorphic: false,
            materialPackageType: "basic",
            returningCondition: "openNotUsed",
            createdAt: "2022-07-11T04:04:20.393Z",
            updatedAt: "2022-07-11T04:04:20.393Z",
            deletedAt: null,
          },
        },
        {
          id: 1344,
          skuId: 664,
          quantity: 0,
          actualQty: 0,
          placeQty: null,
          inspectorId: null,
          placerId: null,
          placeItems: [
            {
              quantity: 0,
              placingId: "738b4a03-126a-4f1f-9066-4ae9460930b2",
              isCompletePlacing: false,
            },
          ],
          faultyQty: 0,
          inspectionProblem: "none",
          returningProblem: "none",
          inspectionProblemDirectInput: null,
          returningProblemDirectInput: null,
          isComplete: false,
          isCompleteInspection: false,
          isCompleteLoading: false,
          area: null,
          locationId: null,
          createdAt: "2022-09-08T00:58:34.912Z",
          updatedAt: "2022-09-08T00:58:34.912Z",
          deletedAt: null,
          sku: {
            id: 664,
            itemName: "강아지 산책용 우비 레인코트 - 네이비 / L",
            productCode: null,
            managementCode: null,
            barCode: null,
            userId: 399,
            bidId: null,
            category: null,
            buyerId: null,
            buyingURL: null,
            isHeteromorphic: false,
            materialPackageType: "basic",
            returningCondition: "openNotUsed",
            createdAt: "2022-07-11T04:04:10.563Z",
            updatedAt: "2022-07-11T04:04:10.563Z",
            deletedAt: null,
          },
        },
        {
          id: 1343,
          skuId: 663,
          quantity: 2,
          actualQty: 0,
          placeQty: null,
          inspectorId: null,
          placerId: null,
          placeItems: [
            {
              quantity: 2,
              placingId: "e726d722-3a61-4df7-9f47-7d4b33670ce3",
              isCompletePlacing: false,
            },
          ],
          faultyQty: 0,
          inspectionProblem: "none",
          returningProblem: "none",
          inspectionProblemDirectInput: null,
          returningProblemDirectInput: null,
          isComplete: false,
          isCompleteInspection: false,
          isCompleteLoading: false,
          area: null,
          locationId: null,
          createdAt: "2022-09-08T00:58:34.909Z",
          updatedAt: "2022-09-08T00:58:34.909Z",
          deletedAt: null,
          sku: {
            id: 663,
            itemName: "강아지 산책용 우비 레인코트 - 네이비 / M",
            productCode: null,
            managementCode: null,
            barCode: null,
            userId: 399,
            bidId: null,
            category: null,
            buyerId: null,
            buyingURL: null,
            isHeteromorphic: false,
            materialPackageType: "basic",
            returningCondition: "openNotUsed",
            createdAt: "2022-07-11T04:04:03.415Z",
            updatedAt: "2022-07-11T04:04:03.415Z",
            deletedAt: null,
          },
        },
        {
          id: 1342,
          skuId: 662,
          quantity: 5,
          actualQty: 0,
          placeQty: null,
          inspectorId: null,
          placerId: null,
          placeItems: [
            {
              quantity: 5,
              placingId: "3fe3f621-a29d-4c1d-a370-823b8972948d",
              isCompletePlacing: false,
            },
          ],
          faultyQty: 0,
          inspectionProblem: "none",
          returningProblem: "none",
          inspectionProblemDirectInput: null,
          returningProblemDirectInput: null,
          isComplete: false,
          isCompleteInspection: false,
          isCompleteLoading: false,
          area: null,
          locationId: null,
          createdAt: "2022-09-08T00:58:34.840Z",
          updatedAt: "2022-09-08T00:58:34.840Z",
          deletedAt: null,
          sku: {
            id: 662,
            itemName: "강아지 산책용 우비 레인코트 - 네이비 / S",
            productCode: null,
            managementCode: null,
            barCode: null,
            userId: 399,
            bidId: null,
            category: null,
            buyerId: null,
            buyingURL: null,
            isHeteromorphic: false,
            materialPackageType: "basic",
            returningCondition: "openNotUsed",
            createdAt: "2022-07-11T04:03:54.690Z",
            updatedAt: "2022-07-11T04:03:54.690Z",
            deletedAt: null,
          },
        },
      ],
      returningPackings: [
        {
          id: 481,
          parcelCompany: null,
          invoiceNo: "654654654654",
          returningId: 275,
          outerPackagesId: null,
          createdAt: "2022-09-08T00:58:34.718Z",
          updatedAt: "2022-09-08T00:59:56.505Z",
          deletedAt: null,
        },
      ],
      shipping: {
        id: 3140,
        shippingStatus: "return",
        wmsStatus: "return",
        deliveringStatus: "done",
        userId: 399,
        managerId: 11,
        pickerId: null,
        packerId: null,
        printerWorkerId: null,
        shipperId: null,
        restockerId: null,
        productGroupIds: [{ qty: 1, productGroupId: 3000001 }],
        truckingId: null,
        warehouseId: 1,
        dueDate: "2022-09-13T00:00:00.000Z",
        shipmentDate: null,
        deliveryType: "parcel",
        orderNo: null,
        channelId: null,
        channel: null,
        integratedType: null,
        customerName: "파이리",
        customerPhone: "456456456456",
        customerAddress: "서울 종로구 청와대로 73 (팔판동)",
        customerDetailAddress: "11-11",
        customerPostalCode: "03054",
        senderName: "aaa",
        senderPhone: "01011111111",
        senderAddress: "인천광역시 인천읍",
        senderDetailAddress: "인천군",
        senderPostalCode: "22222",
        parcelOrder: "firstOrder",
        invoiceNo: "456456456456",
        isIssue: false,
        isConfirm: false,
        sentAt: null,
        parcelCompany: "cj",
        truckCompany: null,
        endedPickingAt: null,
        endedPackingAt: null,
        printCount: 0,
        invoiceErrorMessage: null,
        printedAt: null,
        isStartScanning: false,
        isPacking: true,
        memo: [],
        isCreatedByOMS: false,
        createdAt: "2022-09-08T00:55:58.835Z",
        updatedAt: "2022-09-15T00:42:04.420Z",
        deletedAt: null,
        packings: [
          {
            id: 1101,
            invoiceNo: "456456456456",
            shippingId: 3140,
            outerPackagesId: null,
            createdAt: "2022-09-08T00:56:58.664Z",
            updatedAt: "2022-09-08T00:56:58.664Z",
            deletedAt: null,
          },
        ],
      },
      groups: [
        {
          id: 3000001,
          groupName: "그룹상품2",
          productCode: "112233",
          managementCode: "334455",
          userId: 399,
          materialPackageType: "basic",
          returningCondition: "openNotUsed",
          groupItems: [
            { id: 3, skuId: 662, qty: 2 },
            { id: 4, skuId: 663, qty: 1 },
          ],
          qty: 1,
        },
      ],
    },
    {
      id: 274,
      status: "beforeReturning",
      inspectionStatus: "normal",
      deliveringStatus: "notSent",
      userId: 399,
      productGroupIds: null,
      managerId: null,
      workerList: [],
      returningId: null,
      quantity: 6,
      delivery: "parcel",
      productDetail: "",
      returningPhotoKind: "truckNum",
      uri: "",
      isMatch: null,
      actualQty: 0,
      placeQty: null,
      startInspectionAt: null,
      completeInspectionAt: null,
      agreedAt: null,
      shippingId: 3139,
      dueDate: null,
      expectedDate: null,
      receivedAt: null,
      pickupDate: null,
      applicantName: "피카츄",
      mobile: "0101234567",
      pickupAddress: "서울 종로구 청와대로 73 (팔판동)",
      detailAddress: "11-11",
      postalCode: "03054",
      requests: "",
      parcelCompany: "cj",
      invoiceNo: "987987987987",
      dispatchDate: null,
      isInvoiceMatched: false,
      isAlreadyApplied: false,
      registerCount: 0,
      driver: null,
      warehouseId: 1,
      completeAt: null,
      processStatus: null,
      createdAt: "2022-09-08T00:54:32.290Z",
      updatedAt: "2022-09-08T00:55:10.544Z",
      deletedAt: null,
      user: {
        id: 399,
        accountId: "youtube1",
        email: "qus@ship-da.com",
        invoiceEmail: null,
        name: "aaa",
        lastName: null,
        engName: null,
        phone: "01011111111",
        representativeName: "232",
        address: "232",
        type: "consignee",
        registerType: null,
        freightType: null,
        company: "수입하기정말쉽다",
        engCompany: "K",
        senderName: null,
        senderPhone: null,
        senderAddress: null,
        senderDetailAddress: null,
        senderPostCode: null,
        version: 1,
        country: "KR",
        forwardingManagerId: 55,
        fullfillmentManagerId: 11,
        fullfillmentSalesManagerId: 7,
        BRN: "1112223333",
        businessType: "person",
        isConfirm: false,
        comments: null,
        appliedFulfillmentAt: null,
        bizClass: null,
        bizType: null,
        createdAt: "2020-02-07T05:54:15.000Z",
        updatedAt: "2022-09-14T07:15:05.000Z",
        deletedAt: null,
        lastLoginAt: "2022-09-14T07:15:05.000Z",
      },
      warehouse: {
        id: 1,
        name: "인천1센터",
        address: "인천광역시 인천읍",
        detailAddress: "인천군",
        postalCode: "22222",
        phoneNumber: "032-771-2222",
        createdAt: "2021-05-13T00:56:47.383Z",
        updatedAt: "2022-08-26T05:25:21.108Z",
        deletedAt: null,
      },
      items: [
        {
          id: 1337,
          skuId: 676,
          quantity: 0,
          actualQty: 0,
          placeQty: null,
          inspectorId: null,
          placerId: null,
          placeItems: [
            {
              quantity: 0,
              placingId: "a6581e50-1e21-4256-8d4f-6f93f62d0304",
              isCompletePlacing: false,
            },
          ],
          faultyQty: 0,
          inspectionProblem: "none",
          returningProblem: "none",
          inspectionProblemDirectInput: null,
          returningProblemDirectInput: null,
          isComplete: false,
          isCompleteInspection: false,
          isCompleteLoading: false,
          area: null,
          locationId: null,
          createdAt: "2022-09-08T00:54:32.522Z",
          updatedAt: "2022-09-08T00:54:32.522Z",
          deletedAt: null,
          sku: {
            id: 676,
            itemName: "강아지 안경 - 블랙",
            productCode: null,
            managementCode: null,
            barCode: null,
            userId: 399,
            bidId: null,
            category: null,
            buyerId: null,
            buyingURL: null,
            isHeteromorphic: false,
            materialPackageType: "basic",
            returningCondition: "openNotUsed",
            createdAt: "2022-07-11T04:42:55.762Z",
            updatedAt: "2022-07-11T04:42:55.762Z",
            deletedAt: null,
          },
        },
        {
          id: 1336,
          skuId: 677,
          quantity: 3,
          actualQty: 0,
          placeQty: null,
          inspectorId: null,
          placerId: null,
          placeItems: [
            {
              quantity: 3,
              placingId: "99264e07-17af-471b-ba0c-e4a08cffcc81",
              isCompletePlacing: false,
            },
          ],
          faultyQty: 0,
          inspectionProblem: "none",
          returningProblem: "none",
          inspectionProblemDirectInput: null,
          returningProblemDirectInput: null,
          isComplete: false,
          isCompleteInspection: false,
          isCompleteLoading: false,
          area: null,
          locationId: null,
          createdAt: "2022-09-08T00:54:32.519Z",
          updatedAt: "2022-09-08T00:54:32.519Z",
          deletedAt: null,
          sku: {
            id: 677,
            itemName: "강아지 모자 - 빨강",
            productCode: null,
            managementCode: null,
            barCode: null,
            userId: 399,
            bidId: null,
            category: null,
            buyerId: null,
            buyingURL: null,
            isHeteromorphic: false,
            materialPackageType: "basic",
            returningCondition: "openNotUsed",
            createdAt: "2022-07-11T04:43:24.228Z",
            updatedAt: "2022-07-11T04:43:24.228Z",
            deletedAt: null,
          },
        },
        {
          id: 1335,
          skuId: 678,
          quantity: 3,
          actualQty: 0,
          placeQty: null,
          inspectorId: null,
          placerId: null,
          placeItems: [
            {
              quantity: 3,
              placingId: "5041a8d3-98d1-4ae3-afff-86f60c3411c7",
              isCompletePlacing: false,
            },
          ],
          faultyQty: 0,
          inspectionProblem: "none",
          returningProblem: "none",
          inspectionProblemDirectInput: null,
          returningProblemDirectInput: null,
          isComplete: false,
          isCompleteInspection: false,
          isCompleteLoading: false,
          area: null,
          locationId: null,
          createdAt: "2022-09-08T00:54:32.512Z",
          updatedAt: "2022-09-08T00:54:32.512Z",
          deletedAt: null,
          sku: {
            id: 678,
            itemName: "강아지 모자 - 노랑",
            productCode: null,
            managementCode: null,
            barCode: null,
            userId: 399,
            bidId: null,
            category: null,
            buyerId: null,
            buyingURL: null,
            isHeteromorphic: false,
            materialPackageType: "basic",
            returningCondition: "openNotUsed",
            createdAt: "2022-07-11T04:43:29.461Z",
            updatedAt: "2022-07-11T04:43:29.461Z",
            deletedAt: null,
          },
        },
      ],
      returningPackings: [
        {
          id: 480,
          parcelCompany: null,
          invoiceNo: "987987987987",
          returningId: 274,
          outerPackagesId: null,
          createdAt: "2022-09-08T00:54:32.308Z",
          updatedAt: "2022-09-08T00:59:47.788Z",
          deletedAt: null,
        },
      ],
      shipping: {
        id: 3139,
        shippingStatus: "done",
        wmsStatus: "done",
        deliveringStatus: "done",
        userId: 399,
        managerId: 11,
        pickerId: null,
        packerId: null,
        printerWorkerId: null,
        shipperId: null,
        restockerId: null,
        productGroupIds: [{ qty: 1, productGroupId: 3000000 }],
        truckingId: null,
        warehouseId: 1,
        dueDate: "2022-09-15T00:00:00.000Z",
        shipmentDate: null,
        deliveryType: "parcel",
        orderNo: null,
        channelId: null,
        channel: null,
        integratedType: null,
        customerName: "피카츄",
        customerPhone: "0101234567",
        customerAddress: "서울 종로구 청와대로 73 (팔판동)",
        customerDetailAddress: "11-11",
        customerPostalCode: "03054",
        senderName: "aaa",
        senderPhone: "01011111111",
        senderAddress: "인천광역시 인천읍",
        senderDetailAddress: "인천군",
        senderPostalCode: "22222",
        parcelOrder: "firstOrder",
        invoiceNo: "789789789789",
        isIssue: false,
        isConfirm: false,
        sentAt: null,
        parcelCompany: "cj",
        truckCompany: null,
        endedPickingAt: null,
        endedPackingAt: null,
        printCount: 0,
        invoiceErrorMessage: null,
        printedAt: null,
        isStartScanning: false,
        isPacking: true,
        memo: [],
        isCreatedByOMS: false,
        createdAt: "2022-09-08T00:43:14.426Z",
        updatedAt: "2022-09-15T00:41:29.501Z",
        deletedAt: null,
        packings: [
          {
            id: 1100,
            invoiceNo: "789789789789",
            shippingId: 3139,
            outerPackagesId: 12,
            createdAt: "2022-09-08T00:45:15.895Z",
            updatedAt: "2022-09-08T00:45:15.895Z",
            deletedAt: null,
          },
        ],
      },
      groups: [],
    },
  ],
  total: 2,
};

const PDA_RETURNING_DETAIL = {
  returning: {
    id: 275,
    status: "waitingInspection",
    inspectionStatus: "normal",
    deliveringStatus: "notSent",
    userId: 399,
    productGroupIds: [
      {
        qty: 1,
        productGroupId: 3000001,
      },
      {
        qty: 1,
        productGroupId: 3000000,
      },
    ],
    managerId: 7,
    workerList: [
      {
        id: "7",
        name: "manager5",
      },
    ],
    returningId: null,
    quantity: 17,
    delivery: "parcel",
    productDetail: "",
    returningPhotoKind: "truckNum",
    uri: "",
    isMatch: false,
    actualQty: 7,
    placeQty: 7,
    startInspectionAt: null,
    completeInspectionAt: "2022-09-19T12:54:47.000Z",
    agreedAt: null,
    shippingId: 3140,
    dueDate: null,
    expectedDate: null,
    receivedAt: "2022-09-19T07:59:32.000Z",
    pickupDate: null,
    applicantName: "파이리",
    mobile: "456456456456",
    pickupAddress: "서울 종로구 청와대로 73 (팔판동)",
    detailAddress: "11-11",
    postalCode: "03054",
    requests: "",
    parcelCompany: "cj",
    invoiceNo: "654654654654",
    dispatchDate: null,
    isInvoiceMatched: false,
    isAlreadyApplied: false,
    registerCount: 0,
    driver: null,
    warehouseId: 1,
    completeAt: "2022-09-21T02:09:22.000Z",
    processStatus: "restock",
    createdAt: "2022-09-07T15:58:34.713Z",
    updatedAt: "2022-09-21T19:31:25.769Z",
    deletedAt: null,
    items: [
      {
        id: 1342,
        skuId: 662,
        quantity: 3,
        actualQty: 0,
        placeQty: null,
        inspectorId: null,
        placerId: null,
        placeItems: [
          {
            quantity: 3,
            placingId: "3fe3f621-a29d-4c1d-a370-823b8972948d",
            isCompletePlacing: false,
          },
        ],
        faultyQty: 0,
        inspectionProblem: "none",
        returningProblem: "none",
        inspectionProblemDirectInput: null,
        returningProblemDirectInput: null,
        isComplete: false,
        isCompleteInspection: false,
        isCompleteLoading: false,
        area: null,
        locationId: null,
        productGroupId: null,
        createdAt: "2022-09-07T15:58:34.840Z",
        updatedAt: "2022-09-20T20:22:34.392Z",
        deletedAt: null,
        sku: {
          id: 662,
          itemName: "강아지 산책용 우비 레인코트 - 네이비 / S",
          productCode: null,
          managementCode: null,
          barCode: "123123",
          userId: 399,
          bidId: null,
          category: null,
          buyerId: null,
          buyingURL: null,
          isHeteromorphic: false,
          materialPackageType: "basic",
          returningCondition: "openNotUsed",
          createdAt: "2022-07-10T19:03:54.690Z",
          updatedAt: "2022-07-10T19:03:54.690Z",
          deletedAt: null,
          packages: [
            {
              id: 50,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "일반박스",
              packingName: null,
              description: null,
              materialCode: "GB",
              managementCode: null,
              barCode: null,
              packageCategory: "outside",
              packageType: "box",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:05:05.814Z",
              updatedAt: "2021-11-24T19:39:04.273Z",
              deletedAt: null,
            },
            {
              id: 53,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "에어캡",
              packingName: null,
              description: null,
              materialCode: "AC",
              managementCode: "",
              barCode: null,
              packageCategory: "buffer",
              packageType: "airCap",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:09:28.221Z",
              updatedAt: "2021-11-24T19:39:04.359Z",
              deletedAt: null,
            },
            {
              id: 55,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "OPP 테이프",
              packingName: null,
              description: null,
              materialCode: "OP",
              managementCode: null,
              barCode: null,
              packageCategory: "tape",
              packageType: "oppTape",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:15:36.394Z",
              updatedAt: "2021-11-24T19:39:04.421Z",
              deletedAt: null,
            },
          ],
        },
      },
      {
        id: 1343,
        skuId: 663,
        quantity: 1,
        actualQty: 0,
        placeQty: null,
        inspectorId: null,
        placerId: null,
        placeItems: [
          {
            quantity: 1,
            placingId: "e724d722-3a61-4df7-9f47-7d4b33670ce3",
            isCompletePlacing: false,
          },
        ],
        faultyQty: 0,
        inspectionProblem: "none",
        returningProblem: "none",
        inspectionProblemDirectInput: null,
        returningProblemDirectInput: null,
        isComplete: false,
        isCompleteInspection: false,
        isCompleteLoading: false,
        area: null,
        locationId: null,
        productGroupId: null,
        createdAt: "2022-09-07T15:58:34.909Z",
        updatedAt: "2022-09-20T20:22:34.431Z",
        deletedAt: null,
        sku: {
          id: 663,
          itemName: "강아지 산책용 우비 레인코트 - 네이비 / M",
          productCode: null,
          managementCode: null,
          barCode: "123123",
          userId: 399,
          bidId: null,
          category: null,
          buyerId: null,
          buyingURL: null,
          isHeteromorphic: false,
          materialPackageType: "basic",
          returningCondition: "openNotUsed",
          createdAt: "2022-07-10T19:04:03.415Z",
          updatedAt: "2022-07-10T19:04:03.415Z",
          deletedAt: null,
          packages: [
            {
              id: 50,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "일반박스",
              packingName: null,
              description: null,
              materialCode: "GB",
              managementCode: null,
              barCode: null,
              packageCategory: "outside",
              packageType: "box",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:05:05.814Z",
              updatedAt: "2021-11-24T19:39:04.273Z",
              deletedAt: null,
            },
            {
              id: 53,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "에어캡",
              packingName: null,
              description: null,
              materialCode: "AC",
              managementCode: "",
              barCode: null,
              packageCategory: "buffer",
              packageType: "airCap",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:09:28.221Z",
              updatedAt: "2021-11-24T19:39:04.359Z",
              deletedAt: null,
            },
            {
              id: 55,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "OPP 테이프",
              packingName: null,
              description: null,
              materialCode: "OP",
              managementCode: null,
              barCode: null,
              packageCategory: "tape",
              packageType: "oppTape",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:15:36.394Z",
              updatedAt: "2021-11-24T19:39:04.421Z",
              deletedAt: null,
            },
          ],
        },
      },
      {
        id: 1344,
        skuId: 664,
        quantity: 0,
        actualQty: 0,
        placeQty: null,
        inspectorId: null,
        placerId: null,
        placeItems: [
          {
            quantity: 0,
            placingId: "738b4a03-126a-4f1f-9066-4ae9460930b2",
            isCompletePlacing: false,
          },
        ],
        faultyQty: 0,
        inspectionProblem: "none",
        returningProblem: "none",
        inspectionProblemDirectInput: null,
        returningProblemDirectInput: null,
        isComplete: false,
        isCompleteInspection: false,
        isCompleteLoading: false,
        area: null,
        locationId: null,
        productGroupId: null,
        createdAt: "2022-09-07T15:58:34.912Z",
        updatedAt: "2022-09-07T15:58:34.912Z",
        deletedAt: null,
        sku: {
          id: 664,
          itemName: "강아지 산책용 우비 레인코트 - 네이비 / L",
          productCode: null,
          managementCode: null,
          barCode: null,
          userId: 399,
          bidId: null,
          category: null,
          buyerId: null,
          buyingURL: null,
          isHeteromorphic: false,
          materialPackageType: "basic",
          returningCondition: "openNotUsed",
          createdAt: "2022-07-10T19:04:10.563Z",
          updatedAt: "2022-07-10T19:04:10.563Z",
          deletedAt: null,
          packages: [
            {
              id: 50,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "일반박스",
              packingName: null,
              description: null,
              materialCode: "GB",
              managementCode: null,
              barCode: null,
              packageCategory: "outside",
              packageType: "box",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:05:05.814Z",
              updatedAt: "2021-11-24T19:39:04.273Z",
              deletedAt: null,
            },
            {
              id: 53,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "에어캡",
              packingName: null,
              description: null,
              materialCode: "AC",
              managementCode: "",
              barCode: null,
              packageCategory: "buffer",
              packageType: "airCap",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:09:28.221Z",
              updatedAt: "2021-11-24T19:39:04.359Z",
              deletedAt: null,
            },
            {
              id: 55,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "OPP 테이프",
              packingName: null,
              description: null,
              materialCode: "OP",
              managementCode: null,
              barCode: null,
              packageCategory: "tape",
              packageType: "oppTape",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:15:36.394Z",
              updatedAt: "2021-11-24T19:39:04.421Z",
              deletedAt: null,
            },
          ],
        },
      },
      {
        id: 1345,
        skuId: 665,
        quantity: 0,
        actualQty: 0,
        placeQty: null,
        inspectorId: null,
        placerId: null,
        placeItems: [
          {
            quantity: 0,
            placingId: "6c9bf091-4894-4916-894d-a9a6b9a4e012",
            isCompletePlacing: false,
          },
        ],
        faultyQty: 0,
        inspectionProblem: "none",
        returningProblem: "none",
        inspectionProblemDirectInput: null,
        returningProblemDirectInput: null,
        isComplete: false,
        isCompleteInspection: false,
        isCompleteLoading: false,
        area: null,
        locationId: null,
        productGroupId: null,
        createdAt: "2022-09-07T15:58:34.914Z",
        updatedAt: "2022-09-07T15:58:34.914Z",
        deletedAt: null,
        sku: {
          id: 665,
          itemName: "강아지 산책용 우비 레인코트 - 네이비 / XL",
          productCode: null,
          managementCode: null,
          barCode: null,
          userId: 399,
          bidId: null,
          category: null,
          buyerId: null,
          buyingURL: null,
          isHeteromorphic: false,
          materialPackageType: "basic",
          returningCondition: "openNotUsed",
          createdAt: "2022-07-10T19:04:20.393Z",
          updatedAt: "2022-07-10T19:04:20.393Z",
          deletedAt: null,
          packages: [
            {
              id: 50,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "일반박스",
              packingName: null,
              description: null,
              materialCode: "GB",
              managementCode: null,
              barCode: null,
              packageCategory: "outside",
              packageType: "box",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:05:05.814Z",
              updatedAt: "2021-11-24T19:39:04.273Z",
              deletedAt: null,
            },
            {
              id: 53,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "에어캡",
              packingName: null,
              description: null,
              materialCode: "AC",
              managementCode: "",
              barCode: null,
              packageCategory: "buffer",
              packageType: "airCap",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:09:28.221Z",
              updatedAt: "2021-11-24T19:39:04.359Z",
              deletedAt: null,
            },
            {
              id: 55,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "OPP 테이프",
              packingName: null,
              description: null,
              materialCode: "OP",
              managementCode: null,
              barCode: null,
              packageCategory: "tape",
              packageType: "oppTape",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:15:36.394Z",
              updatedAt: "2021-11-24T19:39:04.421Z",
              deletedAt: null,
            },
          ],
        },
      },
      {
        id: 1346,
        skuId: 663,
        quantity: 1,
        actualQty: 0,
        placeQty: null,
        inspectorId: null,
        placerId: null,
        placeItems: [
          {
            quantity: 1,
            placingId: "3726d722-3a61-4df7-9f47-7d4b33670ce3",
            isCompletePlacing: false,
          },
        ],
        faultyQty: 0,
        inspectionProblem: "none",
        returningProblem: "none",
        inspectionProblemDirectInput: null,
        returningProblemDirectInput: null,
        isComplete: false,
        isCompleteInspection: false,
        isCompleteLoading: false,
        area: null,
        locationId: null,
        productGroupId: 3000001,
        createdAt: "2022-09-07T15:58:34.909Z",
        updatedAt: "2022-09-20T20:23:39.637Z",
        deletedAt: null,
        sku: {
          id: 663,
          itemName: "강아지 산책용 우비 레인코트 - 네이비 / M",
          productCode: null,
          managementCode: null,
          barCode: "123123",
          userId: 399,
          bidId: null,
          category: null,
          buyerId: null,
          buyingURL: null,
          isHeteromorphic: false,
          materialPackageType: "basic",
          returningCondition: "openNotUsed",
          createdAt: "2022-07-10T19:04:03.415Z",
          updatedAt: "2022-07-10T19:04:03.415Z",
          deletedAt: null,
          packages: [
            {
              id: 50,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "일반박스",
              packingName: null,
              description: null,
              materialCode: "GB",
              managementCode: null,
              barCode: null,
              packageCategory: "outside",
              packageType: "box",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:05:05.814Z",
              updatedAt: "2021-11-24T19:39:04.273Z",
              deletedAt: null,
            },
            {
              id: 53,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "에어캡",
              packingName: null,
              description: null,
              materialCode: "AC",
              managementCode: "",
              barCode: null,
              packageCategory: "buffer",
              packageType: "airCap",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:09:28.221Z",
              updatedAt: "2021-11-24T19:39:04.359Z",
              deletedAt: null,
            },
            {
              id: 55,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "OPP 테이프",
              packingName: null,
              description: null,
              materialCode: "OP",
              managementCode: null,
              barCode: null,
              packageCategory: "tape",
              packageType: "oppTape",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:15:36.394Z",
              updatedAt: "2021-11-24T19:39:04.421Z",
              deletedAt: null,
            },
          ],
        },
      },
      {
        id: 1347,
        skuId: 662,
        quantity: 2,
        actualQty: 0,
        placeQty: null,
        inspectorId: null,
        placerId: null,
        placeItems: [
          {
            quantity: 2,
            placingId: "3626d722-3a61-4df7-9f47-7d4b33670ce3",
            isCompletePlacing: false,
          },
        ],
        faultyQty: 0,
        inspectionProblem: "none",
        returningProblem: "none",
        inspectionProblemDirectInput: null,
        returningProblemDirectInput: null,
        isComplete: false,
        isCompleteInspection: false,
        isCompleteLoading: false,
        area: null,
        locationId: null,
        productGroupId: 3000001,
        createdAt: "2022-09-07T15:58:34.909Z",
        updatedAt: "2022-09-20T20:23:39.658Z",
        deletedAt: null,
        sku: {
          id: 662,
          itemName: "강아지 산책용 우비 레인코트 - 네이비 / S",
          productCode: null,
          managementCode: null,
          barCode: "123123",
          userId: 399,
          bidId: null,
          category: null,
          buyerId: null,
          buyingURL: null,
          isHeteromorphic: false,
          materialPackageType: "basic",
          returningCondition: "openNotUsed",
          createdAt: "2022-07-10T19:03:54.690Z",
          updatedAt: "2022-07-10T19:03:54.690Z",
          deletedAt: null,
          packages: [
            {
              id: 50,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "일반박스",
              packingName: null,
              description: null,
              materialCode: "GB",
              managementCode: null,
              barCode: null,
              packageCategory: "outside",
              packageType: "box",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:05:05.814Z",
              updatedAt: "2021-11-24T19:39:04.273Z",
              deletedAt: null,
            },
            {
              id: 53,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "에어캡",
              packingName: null,
              description: null,
              materialCode: "AC",
              managementCode: "",
              barCode: null,
              packageCategory: "buffer",
              packageType: "airCap",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:09:28.221Z",
              updatedAt: "2021-11-24T19:39:04.359Z",
              deletedAt: null,
            },
            {
              id: 55,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "OPP 테이프",
              packingName: null,
              description: null,
              materialCode: "OP",
              managementCode: null,
              barCode: null,
              packageCategory: "tape",
              packageType: "oppTape",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:15:36.394Z",
              updatedAt: "2021-11-24T19:39:04.421Z",
              deletedAt: null,
            },
          ],
        },
      },
      {
        id: 1348,
        skuId: 677,
        quantity: 5,
        actualQty: 0,
        placeQty: null,
        inspectorId: null,
        placerId: null,
        placeItems: [
          {
            quantity: 5,
            placingId: "3226d721-3a61-4df7-9f47-7d4b33670ce3",
            isCompletePlacing: false,
          },
        ],
        faultyQty: 0,
        inspectionProblem: "none",
        returningProblem: "none",
        inspectionProblemDirectInput: null,
        returningProblemDirectInput: null,
        isComplete: false,
        isCompleteInspection: false,
        isCompleteLoading: false,
        area: null,
        locationId: null,
        productGroupId: 3000000,
        createdAt: "2022-09-07T15:58:34.909Z",
        updatedAt: "2022-09-20T20:23:39.658Z",
        deletedAt: null,
        sku: {
          id: 677,
          itemName: "강아지 모자 - 빨강",
          productCode: null,
          managementCode: null,
          barCode: "1111",
          userId: 399,
          bidId: null,
          category: null,
          buyerId: null,
          buyingURL: null,
          isHeteromorphic: false,
          materialPackageType: "basic",
          returningCondition: "openNotUsed",
          createdAt: "2022-07-10T19:43:24.228Z",
          updatedAt: "2022-07-10T19:43:24.228Z",
          deletedAt: null,
          packages: [
            {
              id: 50,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "일반박스",
              packingName: null,
              description: null,
              materialCode: "GB",
              managementCode: null,
              barCode: null,
              packageCategory: "outside",
              packageType: "box",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:05:05.814Z",
              updatedAt: "2021-11-24T19:39:04.273Z",
              deletedAt: null,
            },
            {
              id: 53,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "에어캡",
              packingName: null,
              description: null,
              materialCode: "AC",
              managementCode: "",
              barCode: null,
              packageCategory: "buffer",
              packageType: "airCap",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:09:28.221Z",
              updatedAt: "2021-11-24T19:39:04.359Z",
              deletedAt: null,
            },
            {
              id: 55,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "OPP 테이프",
              packingName: null,
              description: null,
              materialCode: "OP",
              managementCode: null,
              barCode: null,
              packageCategory: "tape",
              packageType: "oppTape",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:15:36.394Z",
              updatedAt: "2021-11-24T19:39:04.421Z",
              deletedAt: null,
            },
          ],
        },
      },
      {
        id: 1349,
        skuId: 678,
        quantity: 5,
        actualQty: 0,
        placeQty: null,
        inspectorId: null,
        placerId: null,
        placeItems: [
          {
            quantity: 5,
            placingId: "8626d722-3a61-4df7-9f47-7d4b33670ce3",
            isCompletePlacing: false,
          },
        ],
        faultyQty: 0,
        inspectionProblem: "none",
        returningProblem: "none",
        inspectionProblemDirectInput: null,
        returningProblemDirectInput: null,
        isComplete: false,
        isCompleteInspection: false,
        isCompleteLoading: false,
        area: null,
        locationId: null,
        productGroupId: 3000000,
        createdAt: "2022-09-07T15:58:34.909Z",
        updatedAt: "2022-09-20T20:23:39.658Z",
        deletedAt: null,
        sku: {
          id: 678,
          itemName: "강아지 모자 - 노랑",
          productCode: null,
          managementCode: null,
          barCode: "111111",
          userId: 399,
          bidId: null,
          category: null,
          buyerId: null,
          buyingURL: null,
          isHeteromorphic: false,
          materialPackageType: "basic",
          returningCondition: "openNotUsed",
          createdAt: "2022-07-10T19:43:29.461Z",
          updatedAt: "2022-07-10T19:43:29.461Z",
          deletedAt: null,
          packages: [
            {
              id: 50,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "일반박스",
              packingName: null,
              description: null,
              materialCode: "GB",
              managementCode: null,
              barCode: null,
              packageCategory: "outside",
              packageType: "box",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:05:05.814Z",
              updatedAt: "2021-11-24T19:39:04.273Z",
              deletedAt: null,
            },
            {
              id: 53,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "에어캡",
              packingName: null,
              description: null,
              materialCode: "AC",
              managementCode: "",
              barCode: null,
              packageCategory: "buffer",
              packageType: "airCap",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:09:28.221Z",
              updatedAt: "2021-11-24T19:39:04.359Z",
              deletedAt: null,
            },
            {
              id: 55,
              packingItemId: null,
              buyerId: null,
              buyingURL: null,
              maximumLoadWeight: null,
              name: "OPP 테이프",
              packingName: null,
              description: null,
              materialCode: "OP",
              managementCode: null,
              barCode: null,
              packageCategory: "tape",
              packageType: "oppTape",
              typeDirectInput: null,
              outerType: "none",
              width: null,
              length: null,
              height: null,
              provider: "shipda",
              userId: null,
              warehouseId: null,
              createdAt: "2021-10-11T22:15:36.394Z",
              updatedAt: "2021-11-24T19:39:04.421Z",
              deletedAt: null,
            },
          ],
        },
      },
    ],
    user: {
      id: 399,
      accountId: "youtube1",
      email: "qus@ship-da.com",
      invoiceEmail: null,
      name: "aaa",
      lastName: null,
      engName: null,
      phone: "01011111111",
      representativeName: "232",
      address: "232",
      type: "consignee",
      registerType: null,
      freightType: null,
      company: "수입하기정말쉽다",
      engCompany: "K",
      senderName: null,
      senderPhone: null,
      senderAddress: null,
      senderDetailAddress: null,
      senderPostCode: null,
      version: 1,
      country: "KR",
      forwardingManagerId: 55,
      fullfillmentManagerId: 11,
      fullfillmentSalesManagerId: 7,
      BRN: "1112223333",
      businessType: "person",
      isConfirm: false,
      comments: null,
      appliedFulfillmentAt: null,
      bizClass: null,
      bizType: null,
      createdAt: "2020-02-06T20:54:15.000Z",
      updatedAt: "2022-09-21T17:29:33.000Z",
      deletedAt: null,
      lastLoginAt: "2022-09-21T17:29:33.000Z",
    },
    shipping: {
      id: 3140,
      shippingStatus: "return",
      wmsStatus: "return",
      deliveringStatus: "done",
      userId: 399,
      managerId: 11,
      pickerId: null,
      packerId: null,
      printerWorkerId: null,
      shipperId: null,
      restockerId: null,
      productGroupIds: [
        {
          qty: 1,
          productGroupId: 3000001,
        },
      ],
      truckingId: null,
      warehouseId: 1,
      dueDate: "2022-09-12T15:00:00.000Z",
      shipmentDate: null,
      deliveryType: "parcel",
      orderNo: null,
      channelId: null,
      channel: null,
      integratedType: null,
      customerName: "파이리",
      customerPhone: "456456456456",
      customerAddress: "서울 종로구 청와대로 73 (팔판동)",
      customerDetailAddress: "11-11",
      customerPostalCode: "03054",
      senderName: "aaa",
      senderPhone: "01011111111",
      senderAddress: "인천광역시 인천읍",
      senderDetailAddress: "인천군",
      senderPostalCode: "22222",
      parcelOrder: "firstOrder",
      invoiceNo: "456456456456",
      isIssue: false,
      isConfirm: false,
      sentAt: null,
      parcelCompany: "cj",
      truckCompany: null,
      endedPickingAt: null,
      endedPackingAt: null,
      printCount: 0,
      invoiceErrorMessage: null,
      printedAt: null,
      isStartScanning: false,
      isPacking: true,
      memo: [],
      isCreatedByOMS: false,
      createdAt: "2022-09-07T15:55:58.835Z",
      updatedAt: "2022-09-14T15:42:04.420Z",
      deletedAt: null,
      items: [
        {
          id: 2961,
          skuId: 662,
          shippingId: 3140,
          locationId: 118,
          quantity: 5,
          currentQty: 0,
          createdAt: "2022-09-07T15:55:58.856Z",
          updatedAt: "2022-09-07T15:55:58.856Z",
          deletedAt: null,
        },
        {
          id: 2962,
          skuId: 663,
          shippingId: 3140,
          locationId: 117,
          quantity: 5,
          currentQty: 0,
          createdAt: "2022-09-07T15:55:58.858Z",
          updatedAt: "2022-09-07T15:55:58.858Z",
          deletedAt: null,
        },
        {
          id: 2963,
          skuId: 664,
          shippingId: 3140,
          locationId: 117,
          quantity: 5,
          currentQty: 0,
          createdAt: "2022-09-07T15:55:58.860Z",
          updatedAt: "2022-09-07T15:55:58.860Z",
          deletedAt: null,
        },
        {
          id: 2964,
          skuId: 665,
          shippingId: 3140,
          locationId: 117,
          quantity: 5,
          currentQty: 0,
          createdAt: "2022-09-07T15:55:58.878Z",
          updatedAt: "2022-09-07T15:55:58.878Z",
          deletedAt: null,
        },
      ],
      packings: [
        {
          id: 1101,
          invoiceNo: "456456456456",
          shippingId: 3140,
          outerPackagesId: null,
          createdAt: "2022-09-07T15:56:58.664Z",
          updatedAt: "2022-09-07T15:56:58.664Z",
          deletedAt: null,
          packingToSku: [
            {
              id: 1611,
              packingId: 1101,
              returningPackingId: null,
              stockUnitId: 662,
              qty: 5,
            },
            {
              id: 1612,
              packingId: 1101,
              returningPackingId: null,
              stockUnitId: 663,
              qty: 5,
            },
            {
              id: 1613,
              packingId: 1101,
              returningPackingId: null,
              stockUnitId: 664,
              qty: 5,
            },
            {
              id: 1614,
              packingId: 1101,
              returningPackingId: null,
              stockUnitId: 665,
              qty: 5,
            },
          ],
        },
      ],
    },
    returningPackings: [
      {
        id: 481,
        parcelCompany: null,
        invoiceNo: "654654654654",
        returningId: 275,
        outerPackagesId: null,
        createdAt: "2022-09-07T15:58:34.718Z",
        updatedAt: "2022-09-07T15:59:56.505Z",
        deletedAt: null,
        packingToSku: [
          {
            id: 1618,
            packingId: null,
            returningPackingId: 481,
            stockUnitId: 665,
            qty: 0,
          },
          {
            id: 1617,
            packingId: null,
            returningPackingId: 481,
            stockUnitId: 664,
            qty: 0,
          },
          {
            id: 1616,
            packingId: null,
            returningPackingId: 481,
            stockUnitId: 663,
            qty: 2,
          },
          {
            id: 1615,
            packingId: null,
            returningPackingId: 481,
            stockUnitId: 662,
            qty: 5,
          },
        ],
      },
    ],
    groups: [
      {
        id: 3000000,
        groupName: "그룹상품1",
        productCode: "123123",
        managementCode: "456456",
        userId: 399,
        materialPackageType: "basic",
        returningCondition: "openAndUsed",
        groupItems: [
          {
            id: 2,
            skuId: 677,
            qty: 5,
          },
          {
            id: 1,
            skuId: 678,
            qty: 5,
          },
        ],
        qty: 1,
      },
      {
        id: 3000001,
        groupName: "그룹상품2",
        productCode: "112233",
        managementCode: "334455",
        userId: 399,
        materialPackageType: "basic",
        returningCondition: "openNotUsed",
        groupItems: [
          {
            id: 4,
            skuId: 663,
            qty: 1,
          },
          {
            id: 3,
            skuId: 662,
            qty: 2,
          },
        ],
        qty: 1,
      },
    ],
  },
  attachment: [
    {
      id: 535,
      isBinded: true,
      actorId: 3161,
      actorKind: "consignee",
      bucket: "boful-files-dev",
      key: "sku/4de4cbf7-a7a5-4e4e-9fe2-b8b9cab38d37.jpeg",
      name: "dogToy.jpeg",
      domain: "sku",
      targetId: 275,
      metadata: null,
      createdAt: "2021-12-08T15:35:52.965Z",
      updatedAt: "2021-12-08T15:35:53.000Z",
      deletedAt: null,
    },
    {
      id: 1880,
      isBinded: true,
      actorId: 6666,
      actorKind: "consignee",
      bucket: "boful-files-dev",
      key: "subsidiaryMaterials/ab751475-5b91-4732-9c7a-cb9b37a0e477.jpeg",
      name: "testImage.jpeg",
      domain: "subsidiaryMaterials",
      targetId: 275,
      metadata: null,
      createdAt: "2022-08-25T17:09:14.881Z",
      updatedAt: "2022-08-25T17:09:16.000Z",
      deletedAt: null,
    },
    {
      id: 1881,
      isBinded: true,
      actorId: 6666,
      actorKind: "consignee",
      bucket: "boful-files-dev",
      key: "subsidiaryMaterials/f60a398f-819d-403e-afc9-a3c613f1aa19.png",
      name: "testImage2.png",
      domain: "subsidiaryMaterials",
      targetId: 275,
      metadata: null,
      createdAt: "2022-08-25T17:09:14.953Z",
      updatedAt: "2022-08-25T17:09:16.000Z",
      deletedAt: null,
    },
  ],
};

const USER_RETURNING_LIST = {
  list: [
    {
      id: 300,
      status: "beforeReturning",
      inspectionStatus: "normal",
      deliveringStatus: "notSent",
      userId: 399,
      productGroupIds: [
        {
          qty: 2,
          groupItems: [
            {
              id: 3,
              qty: 2,
              skuId: 662,
              deletedAt: null,
              productGroupId: 3000001,
            },
            {
              id: 4,
              qty: 1,
              skuId: 663,
              deletedAt: null,
              productGroupId: 3000001,
            },
          ],
          productGroupId: 3000001,
          productGroupName: "그룹상품2",
        },
      ],
      managerId: null,
      workerList: [],
      returningId: null,
      quantity: 17,
      delivery: "parcel",
      productDetail: "",
      returningPhotoKind: "truckNum",
      uri: "",
      isMatch: null,
      actualQty: 0,
      placeQty: null,
      startInspectionAt: null,
      completeInspectionAt: null,
      agreedAt: null,
      shippingId: 64757,
      dueDate: "2022-10-26T14:25:56.000Z",
      expectedDate: null,
      receivedAt: null,
      pickupDate: "2022-10-26T14:25:56.000Z",
      applicantName: "파이리",
      mobile: "456456456456",
      pickupAddress: "서울 종로구 청와대로 73 (팔판동)",
      detailAddress: "11-11",
      postalCode: "03054",
      requests: "",
      parcelCompany: "cj",
      invoiceNo: "9847363647",
      dispatchDate: "2022-10-26T14:25:56.000Z",
      isInvoiceMatched: false,
      isAlreadyApplied: false,
      registerCount: 0,
      driver: null,
      warehouseId: 1,
      completeAt: null,
      processStatus: null,
      createdAt: "2022-10-26T05:35:05.136Z",
      updatedAt: "2022-10-26T06:22:33.472Z",
      deletedAt: null,
      shipping: {
        id: 64757,
        shippingStatus: "return",
        wmsStatus: "return",
        deliveringStatus: "done",
        userId: 399,
        managerId: 11,
        pickerId: null,
        packerId: null,
        printerWorkerId: null,
        shipperId: null,
        restockerId: null,
        productGroupIds: [
          {
            qty: 3,
            groupItems: [
              {
                id: 3,
                qty: 2,
                skuId: 662,
                deletedAt: null,
                productGroupId: 3000001,
              },
              {
                id: 4,
                qty: 1,
                skuId: 663,
                deletedAt: null,
                productGroupId: 3000001,
              },
            ],
            productGroupId: 3000001,
            productGroupName: "그룹상품2",
          },
        ],
        truckingId: null,
        warehouseId: 1,
        dueDate: "2022-09-13T00:00:00.000Z",
        shipmentDate: null,
        deliveryType: "parcel",
        orderNo: null,
        channelId: null,
        channel: null,
        integratedType: null,
        customerName: "파이리",
        customerPhone: "456456456456",
        customerAddress: "서울 종로구 청와대로 73 (팔판동)",
        customerDetailAddress: "11-11",
        customerPostalCode: "03054",
        senderName: "aaa",
        senderPhone: "01011111111",
        senderAddress: "인천광역시 인천읍",
        senderDetailAddress: "인천군",
        senderPostalCode: "22222",
        parcelOrder: "firstOrder",
        invoiceNo: "456456456456",
        isIssue: false,
        isConfirm: false,
        sentAt: null,
        parcelCompany: "cj",
        truckCompany: null,
        endedPickingAt: null,
        endedPackingAt: null,
        printCount: 0,
        invoiceErrorMessage: null,
        printedAt: null,
        isStartScanning: false,
        isPacking: true,
        memo: [],
        isCreatedByOMS: false,
        createdAt: "2022-09-08T00:55:58.835Z",
        updatedAt: "2022-10-26T05:31:53.000Z",
        deletedAt: null,
        items: [
          {
            id: 2989,
            skuId: 662,
            shippingId: 64757,
            locationId: 118,
            quantity: 10,
            currentQty: 0,
            createdAt: "2022-09-08T00:55:58.856Z",
            updatedAt: "2022-09-08T00:55:58.856Z",
            deletedAt: null,
          },
          {
            id: 2990,
            skuId: 663,
            shippingId: 64757,
            locationId: 117,
            quantity: 5,
            currentQty: 0,
            createdAt: "2022-09-08T00:55:58.858Z",
            updatedAt: "2022-09-08T00:55:58.858Z",
            deletedAt: null,
          },
          {
            id: 2991,
            skuId: 664,
            shippingId: 64757,
            locationId: 117,
            quantity: 5,
            currentQty: 0,
            createdAt: "2022-09-08T00:55:58.860Z",
            updatedAt: "2022-09-08T00:55:58.860Z",
            deletedAt: null,
          },
          {
            id: 2992,
            skuId: 665,
            shippingId: 64757,
            locationId: 117,
            quantity: 5,
            currentQty: 0,
            createdAt: "2022-09-08T00:55:58.878Z",
            updatedAt: "2022-09-08T00:55:58.878Z",
            deletedAt: null,
          },
        ],
        packings: [
          {
            id: 1509,
            invoiceNo: "456456456456",
            shippingId: 64757,
            outerPackagesId: null,
            createdAt: "2022-09-08T00:56:58.664Z",
            updatedAt: "2022-09-08T00:56:58.664Z",
            deletedAt: null,
          },
        ],
        trucking: null,
      },
      items: [
        {
          id: 1499,
          skuId: 662,
          quantity: 4,
          actualQty: 0,
          placeQty: null,
          inspectorId: null,
          placerId: null,
          placeItems: [
            {
              quantity: 5,
              placingId: "13404adc-3953-4ea9-b757-30060e4d11f4",
              isCompletePlacing: false,
            },
          ],
          faultyQty: 0,
          inspectionProblem: "none",
          returningProblem: "none",
          inspectionProblemDirectInput: null,
          returningProblemDirectInput: null,
          isComplete: false,
          isCompleteInspection: false,
          isCompleteLoading: false,
          area: null,
          locationId: null,
          productGroupId: null,
          createdAt: "2022-10-26T05:35:05.533Z",
          updatedAt: "2022-10-26T05:35:05.533Z",
          deletedAt: null,
          sku: {
            id: 662,
            itemName: "강아지 산책용 우비 레인코트 - 네이비 / S",
            productCode: null,
            managementCode: null,
            barCode: "123123",
            userId: 399,
            bidId: null,
            category: null,
            buyerId: null,
            buyingURL: null,
            isHeteromorphic: false,
            materialPackageType: "basic",
            returningCondition: "openNotUsed",
            createdAt: "2022-07-11T04:03:54.690Z",
            updatedAt: "2022-09-23T05:52:40.000Z",
            deletedAt: null,
          },
        },
        {
          id: 1500,
          skuId: 663,
          quantity: 3,
          actualQty: 0,
          placeQty: null,
          inspectorId: null,
          placerId: null,
          placeItems: [
            {
              quantity: 5,
              placingId: "aba81221-0b23-426c-99bb-4495fbf66833",
              isCompletePlacing: false,
            },
          ],
          faultyQty: 0,
          inspectionProblem: "none",
          returningProblem: "none",
          inspectionProblemDirectInput: null,
          returningProblemDirectInput: null,
          isComplete: false,
          isCompleteInspection: false,
          isCompleteLoading: false,
          area: null,
          locationId: null,
          productGroupId: null,
          createdAt: "2022-10-26T05:35:05.556Z",
          updatedAt: "2022-10-26T05:35:05.556Z",
          deletedAt: null,
          sku: {
            id: 663,
            itemName: "강아지 산책용 우비 레인코트 - 네이비 / M",
            productCode: null,
            managementCode: null,
            barCode: "123123",
            userId: 399,
            bidId: null,
            category: null,
            buyerId: null,
            buyingURL: null,
            isHeteromorphic: false,
            materialPackageType: "basic",
            returningCondition: "openNotUsed",
            createdAt: "2022-07-11T04:04:03.415Z",
            updatedAt: "2022-09-23T05:52:35.000Z",
            deletedAt: null,
          },
        },
        {
          id: 1501,
          skuId: 664,
          quantity: 5,
          actualQty: 0,
          placeQty: null,
          inspectorId: null,
          placerId: null,
          placeItems: [
            {
              quantity: 5,
              placingId: "03dbceb6-0806-4ee5-baca-00621da798ac",
              isCompletePlacing: false,
            },
          ],
          faultyQty: 0,
          inspectionProblem: "none",
          returningProblem: "none",
          inspectionProblemDirectInput: null,
          returningProblemDirectInput: null,
          isComplete: false,
          isCompleteInspection: false,
          isCompleteLoading: false,
          area: null,
          locationId: null,
          productGroupId: null,
          createdAt: "2022-10-26T05:35:05.581Z",
          updatedAt: "2022-10-26T05:35:05.581Z",
          deletedAt: null,
          sku: {
            id: 664,
            itemName: "강아지 산책용 우비 레인코트 - 네이비 / L",
            productCode: null,
            managementCode: null,
            barCode: null,
            userId: 399,
            bidId: null,
            category: null,
            buyerId: null,
            buyingURL: null,
            isHeteromorphic: false,
            materialPackageType: "basic",
            returningCondition: "openNotUsed",
            createdAt: "2022-07-11T04:04:10.563Z",
            updatedAt: "2022-07-11T04:04:10.563Z",
            deletedAt: null,
          },
        },
        {
          id: 1502,
          skuId: 665,
          quantity: 5,
          actualQty: 0,
          placeQty: null,
          inspectorId: null,
          placerId: null,
          placeItems: [
            {
              quantity: 5,
              placingId: "42e004c4-14d5-424f-a577-8b2fbadb0a27",
              isCompletePlacing: false,
            },
          ],
          faultyQty: 0,
          inspectionProblem: "none",
          returningProblem: "none",
          inspectionProblemDirectInput: null,
          returningProblemDirectInput: null,
          isComplete: false,
          isCompleteInspection: false,
          isCompleteLoading: false,
          area: null,
          locationId: null,
          productGroupId: null,
          createdAt: "2022-10-26T05:35:05.605Z",
          updatedAt: "2022-10-26T05:35:05.605Z",
          deletedAt: null,
          sku: {
            id: 665,
            itemName: "강아지 산책용 우비 레인코트 - 네이비 / XL",
            productCode: null,
            managementCode: null,
            barCode: null,
            userId: 399,
            bidId: null,
            category: null,
            buyerId: null,
            buyingURL: null,
            isHeteromorphic: false,
            materialPackageType: "basic",
            returningCondition: "openNotUsed",
            createdAt: "2022-07-11T04:04:20.393Z",
            updatedAt: "2022-07-11T04:04:20.393Z",
            deletedAt: null,
          },
        },
      ],
      user: {
        id: 399,
        accountId: "youtube1",
        email: "qus@ship-da.com",
        invoiceEmail: null,
        name: "aaa",
        lastName: null,
        engName: null,
        phone: "01011111111",
        representativeName: "232",
        address: "232",
        type: "consignee",
        registerType: null,
        freightType: null,
        company: "수입하기정말쉽다",
        engCompany: "K",
        senderName: null,
        senderPhone: null,
        senderAddress: null,
        senderDetailAddress: null,
        senderPostCode: null,
        version: 1,
        country: "KR",
        forwardingManagerId: 55,
        fullfillmentManagerId: 11,
        fullfillmentSalesManagerId: 7,
        BRN: "1112223333",
        businessType: "person",
        isConfirm: false,
        comments: null,
        appliedFulfillmentAt: null,
        bizClass: null,
        bizType: null,
        createdAt: "2020-02-07T05:54:15.000Z",
        updatedAt: "2022-10-26T05:32:03.000Z",
        deletedAt: null,
        lastLoginAt: "2022-10-26T05:32:04.000Z",
      },
      returningPackings: [
        {
          id: 509,
          parcelCompany: null,
          invoiceNo: "9847363647",
          returningId: 300,
          outerPackagesId: null,
          createdAt: "2022-10-26T05:35:05.166Z",
          updatedAt: "2022-10-26T06:23:05.055Z",
          deletedAt: null,
        },
      ],
    },
  ],
  total: 50,
};

export { ADMIN_RETURNING_LIST, PDA_RETURNING_DETAIL, USER_RETURNING_LIST };
