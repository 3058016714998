import { useMemo } from "react";

import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";

import { ForwardingAdminUserListItem } from "../../../types/forwarding/adminUser";

import {
  QueryResponseHandlerCustomMessage,
  QueryResponseHandlerFailureModalInfo,
  QueryResponseHandlerSuccessModalInfo,
} from "../../../components/QueryResponseHandler";

import {
  DELETE_ADMIN_USER_REQ,
  DELETE_ADMIN_USER_RES,
  GET_ADMIN_USER_DETAIL_REQ,
  GET_ADMIN_USER_DETAIL_RES,
  GET_ADMIN_USER_LIST_RES,
  UPDATE_ADMIN_USER_DETAIL_REQ,
  UPDATE_ADMIN_USER_DETAIL_RES,
} from "../../../api-interfaces/shipda-api/admin/adminUser";

export const ADMIN_USER_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_USER_QUERY" }] as const,

  getAdminUserList: () =>
    [
      {
        ...ADMIN_USER_QUERY_KEY_GEN.all()[0],
        entity: "getAdminUserList",
      },
    ] as const,

  getAdminUserDetail: (params: GET_ADMIN_USER_DETAIL_REQ) =>
    [
      {
        ...ADMIN_USER_QUERY_KEY_GEN.all()[0],
        params,
        entity: "getAdminUserDetail",
      },
    ] as const,
};

function useGetAdminUserList() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_USER_QUERY_KEY_GEN.getAdminUserList>,
    GET_ADMIN_USER_LIST_RES
  >({
    queryKey: ADMIN_USER_QUERY_KEY_GEN.getAdminUserList(),
    requestOptions: {
      method: "get",
      path: "/auth",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  const adminUserList = useMemo(() => {
    return queryResult.data?.list;
  }, [queryResult.data?.list]);

  const adminDataForTableFilter = useMemo(() => {
    return queryResult.data?.list
      ?.filter((v: ForwardingAdminUserListItem) => {
        return v.isForwardingManager;
      })
      .map((n: ForwardingAdminUserListItem) => {
        return { text: n.name, value: n.id };
      });
  }, [queryResult.data]);

  return {
    ...queryResult,
    adminDataForTableFilter,
    adminUserList,
  };
}

function useGetAdminUserDetail(params: GET_ADMIN_USER_DETAIL_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_USER_QUERY_KEY_GEN.getAdminUserDetail>,
    GET_ADMIN_USER_DETAIL_RES
  >({
    queryKey: ADMIN_USER_QUERY_KEY_GEN.getAdminUserDetail(params),
    requestOptions: {
      method: "get",
      path: `auth/${params.adminId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    ...(typeof params.enabled === "boolean" ? { enabled: params.enabled } : {}),
  });

  return { ...queryResult };
}

function useUpdateAdminUserDetail(options?: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
}) {
  const mutation = useAppMutation<
    UPDATE_ADMIN_USER_DETAIL_REQ,
    UPDATE_ADMIN_USER_DETAIL_RES
  >({
    requestOptions: {
      method: "put",
      path: `/auth`,
      apiType: "ShipdaAdminDefaultNew",
    },

    successModalInfo: options?.successModalInfo,
    failureModalInfo: options?.failureModalInfo,
  });

  return { ...mutation };
}

function useDeleteAdminUserDetail(param: DELETE_ADMIN_USER_REQ) {
  const mutation = useAppMutation<DELETE_ADMIN_USER_REQ, DELETE_ADMIN_USER_RES>(
    {
      requestOptions: {
        method: "delete",
        path: `/auth/${param.adminId}`,
        apiType: "ShipdaAdminDefaultNew",
      },
      successModalInfo: param.options?.successModalInfo,

      failureModalInfo: {
        customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
          const getErrorMessage = ({
            errorCode,
          }: {
            errorCode: string | undefined;
          }) =>
            ({
              E404: {
                messageType: "titleOnly" as const,
                title: "삭제에 실패했습니다.",
              },

              E366: {
                messageType: "titleOnly" as const,
                title: "관리자로 등록된 유저는 삭제할 수 없습니다.",
              },
            }[errorCode ?? ""] ?? {
              title: "에러가 발생했습니다 개발자에게 문의해주세요.",
            });

          return getErrorMessage({ errorCode: failureInfo?.errorCode });
        },
      },
    }
  );

  return { ...mutation };
}

const ADMIN_USER_QUERY = {
  useGetAdminUserList,
  useGetAdminUserDetail,
  useUpdateAdminUserDetail,
  useDeleteAdminUserDetail,
};
export default ADMIN_USER_QUERY;
