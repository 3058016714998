import { GET_BID_TRACKING_SHIP_RES } from "../../../api-interfaces/shipda-api/bid";

export const GET_BID_TRELLO_LIST = {
  list: [
    {
      id: 123,
      management: {
        BLType: "HBL",
        forwardingManagerId: 52,
        bidId: 102725,
        detailStatus: null,
        confirmFlag: false,
        customsPaymentFlag: false,
        ETD: null,
      },
      projectStatus: "beforeContactPartner",
      freightType: "FCL",
      incoterms: "FOB",
      attachmentFlag: false,
      invoices: null,
      containCustoms: false,
      user: {
        company: "우왕ㅋ굳ㅋ",
      },
      partnerManagement: {
        inlandPartnerCompanyId: null,
      },
      endAddress: null,
      endAddressDetail: null,
      endPort: {
        code: "KRPUS",
        codeMT: "KRPUS",
        country: "KR",
        id: 1,
        isActiveOceanTicketPort: true,
        isOceanTicketOriginPort: true,
        lat: 35.108155,
        lng: 129.053807,
        name: "부산항",
        nameC: "부산구항",
        nameEN: "Busan port",
        order: 2,
        region: "Asia",
        type: "sea",
      },
      endViaPort: null,
      endCountry: "KR",
      startCountry: "CN",
      startPort: {
        code: "CNSGH",
        codeMT: null,
        country: "CN",
        id: 43,
        isActiveOceanTicketPort: true,
        isOceanTicketOriginPort: true,
        lat: 31.339001,
        lng: 121.660112,
        name: "상해항",
        nameC: "상하이항",
        nameEN: "Shanghai port",
        order: 1,
        region: "Asia",
        type: "sea",
      },
      startViaPort: null,
      startAddress: null,
      zone: {
        name: null,
      },
      wareHouse: {
        addressCN: null,
      },
      serviceType: "general",
    },

    {
      id: 1234,
      management: {
        BLType: "HBL",
        forwardingManagerId: 52,
        bidId: 101725,
        detailStatus: null,
        confirmFlag: false,
        customsPaymentFlag: false,
        ETD: null,
      },
      projectStatus: "beforeContactPartner",
      freightType: "FCL",
      incoterms: "FOB",
      attachmentFlag: false,
      invoices: null,
      containCustoms: false,
      user: {
        company: "우왕ㅋ굳ㅋ",
      },
      partnerManagement: {
        inlandPartnerCompanyId: null,
      },
      endAddress: null,
      endAddressDetail: null,
      endPort: {
        code: "KRPUS",
        codeMT: "KRPUS",
        country: "KR",
        id: 1,
        isActiveOceanTicketPort: true,
        isOceanTicketOriginPort: true,
        lat: 35.108155,
        lng: 129.053807,
        name: "부산항",
        nameC: "부산구항",
        nameEN: "Busan port",
        order: 2,
        region: "Asia",
        type: "sea",
      },
      endViaPort: null,
      endCountry: "KR",
      startCountry: "CN",
      startPort: {
        code: "CNSGH",
        codeMT: null,
        country: "CN",
        id: 43,
        isActiveOceanTicketPort: true,
        isOceanTicketOriginPort: true,
        lat: 31.339001,
        lng: 121.660112,
        name: "상해항",
        nameC: "상하이항",
        nameEN: "Shanghai port",
        order: 1,
        region: "Asia",
        type: "sea",
      },
      startViaPort: null,
      startAddress: null,
      zone: {
        name: null,
      },
      wareHouse: {
        addressCN: null,
      },
      serviceType: "general",
    },

    {
      id: 1235,
      management: {
        BLType: "HBL",
        forwardingManagerId: 52,
        bidId: 102225,
        detailStatus: null,
        confirmFlag: false,
        customsPaymentFlag: false,
        ETD: null,
      },
      projectStatus: "beforeContactPartner",
      freightType: "FCL",
      incoterms: "FOB",
      attachmentFlag: false,
      invoices: null,
      containCustoms: false,
      user: {
        company: "우왕ㅋ굳ㅋ",
      },
      partnerManagement: {
        inlandPartnerCompanyId: null,
      },
      endAddress: null,
      endAddressDetail: null,
      endPort: {
        code: "KRPUS",
        codeMT: "KRPUS",
        country: "KR",
        id: 1,
        isActiveOceanTicketPort: true,
        isOceanTicketOriginPort: true,
        lat: 35.108155,
        lng: 129.053807,
        name: "부산항",
        nameC: "부산구항",
        nameEN: "Busan port",
        order: 2,
        region: "Asia",
        type: "sea",
      },
      endViaPort: null,
      endCountry: "KR",
      startCountry: "CN",
      startPort: {
        code: "CNSGH",
        codeMT: null,
        country: "CN",
        id: 43,
        isActiveOceanTicketPort: true,
        isOceanTicketOriginPort: true,
        lat: 31.339001,
        lng: 121.660112,
        name: "상해항",
        nameC: "상하이항",
        nameEN: "Shanghai port",
        order: 1,
        region: "Asia",
        type: "sea",
      },
      startViaPort: null,
      startAddress: null,
      zone: {
        name: null,
      },
      wareHouse: {
        addressCN: null,
      },
      serviceType: "general",
    },
  ],
  total: 134,
};

export const GET_TRELLO_ATTACHMENTS = [
  {
    bucket: "sellernote-files-dev",
    createdAt: "2022-08-29T01:41:54.000Z",
    deletedAt: null,
    domain: "CI_PL",
    author: "조형근",
    authority: "Admin",
    id: 3035,
    description: "파일 설명 예시 데이터",
    key: "CI_PL/cb39d9be-f652-4468-ba41-68a952c31038.png",
    name: "CI_PL.png",
  },
  {
    bucket: "sellernote-files-dev",
    createdAt: "2022-08-29T01:41:54.000Z",
    deletedAt: null,
    domain: "CO",
    author: "조형근",
    authority: "Admin",
    id: 2973,
    description: "파일 설명 예시 데이터",
    key: "CO/1884e191-66d9-4324-b943-e53dca13b34c.pdf",
    name: "CO스크린샷 2022-08-25 오후 5.43.32.png",
  },
  {
    bucket: "sellernote-files-dev",
    createdAt: "2022-08-29T01:41:54.000Z",
    deletedAt: null,
    domain: "BL",
    author: "조형근",
    authority: "Admin",
    id: 2970,
    description: "파일 설명 예시 데이터",
    key: "BL/b67addd7-0532-4408-ac08-2ef22bf696da.pdf",
    name: "BL스크린샷 2022-08-25 오후 5.43.32.png",
  },
  {
    bucket: "sellernote-files-dev",
    createdAt: "2022-08-29T01:41:54.000Z",
    deletedAt: null,
    domain: "BL",
    author: "조형근",
    authority: "Admin",
    id: 2979,
    description: "파일 설명 예시 데이터",
    key: "BL/b67addd7-0532-4408-ac08-2ef22bf696da.pdf",
    name: "BL2스크린샷 2022-08-25 오후 5.43.32.png",
  },
  {
    bucket: "sellernote-files-dev",
    createdAt: "2022-08-29T01:41:54.000Z",
    deletedAt: null,
    domain: "BL",
    author: "조형근",
    authority: "Admin",
    id: 2972,
    description: "파일 설명 예시 데이터",
    key: "BL/b67addd7-0532-4408-ac08-2ef22bf696da.pdf",
    name: "BL2스크린샷 2022-08-25 오후 5.43.32.png",
  },
];

export const GET_BID_TRELLO_LIST_DETAIL = {
  accountPayables: [
    {
      id: 384,
      bidId: 103038,
      domain: "foreign",
      partnerCompanyId: 607,
      partnerManagersIds: [4989],
      customsPartnerManagerId: null,
      isPaymentComplete: false,
      sentEmailToManagers: true,
      requestId: null,
      receiptId: null,
      isForeignWithdrawalTarget: false,
      customsClearanceStartFlag: false,
      createdAt: "2022-09-16T01:04:10.000Z",
      updatedAt: "2022-09-16T01:04:10.000Z",
      deletedAt: null,
      partner: {
        id: 607,
        country: "CN",
        language: "en",
        name: "GLOBELINKSHA",
        email: "",
        businessArea: "foreign",
        transportMode: "LCL",
        needsPlainTextEmail: true,
        settlementFlag: false,
        bankName: "",
        accountNumber: "",
        invoiceIssueFlag: false,
        BRNNumber: "",
        createdAt: "2021-05-04T06:34:27.000Z",
        isDel: false,
        deletedAt: null,
        BRNId: null,
        createUserId: null,
      },
      receipt: null,
      request: null,
    },
    {
      id: 941,
      bidId: 103038,
      domain: "customs",
      partnerCompanyId: 2211,
      partnerManagersIds: null,
      customsPartnerManagerId: null,
      isPaymentComplete: false,
      sentEmailToManagers: false,
      requestId: null,
      receiptId: null,
      isForeignWithdrawalTarget: false,
      customsClearanceStartFlag: false,
      createdAt: "2022-09-16T01:04:10.000Z",
      updatedAt: "2022-09-16T01:04:10.000Z",
      deletedAt: null,
      partner: {
        id: 2211,
        country: "CN",
        language: "kr",
        name: "쉽다 관세사 생성 테스트",
        email: "geunee92@gmail.com",
        businessArea: "shipDaCustoms",
        transportMode: "ALL",
        needsPlainTextEmail: false,
        settlementFlag: false,
        bankName: null,
        accountNumber: null,
        invoiceIssueFlag: false,
        BRNNumber: null,
        createdAt: "2021-08-17T06:11:33.000Z",
        isDel: false,
        deletedAt: null,
        BRNId: null,
        createUserId: null,
      },
      receipt: null,
      request: null,
    },
  ],
  id: 102894,
  userId: 6686,
  status: "inProgress",
  projectStatus: "portEntryAndPrepareCustoms",
  remarketingFlag: false,
  isFixed: true,
  freightType: "FCL",
  serviceType: "general",
  transportType: "sea",
  price: 585788,
  supply: 0.5,
  incoterms: "FOB",
  startCountry: "CN",
  startPortId: 43,
  startViaPortId: null,
  startAddress: null,
  startType: "sea",
  endCountry: "KR",
  endPortId: 336,
  endViaPortId: null,
  zoneId: null,
  endAddress: null,
  endAddressDetail: null,
  isImport: true,
  isExpress: false,
  endType: "sea",
  hopeCargoInsurance: false,
  fareId: 469,
  containerAccessable: null,
  containersInfo: [
    {
      name: "test",
      weight: null,
      canStack: true,
      quantity: 1,
      weightUnit: "TON",
      isDangerous: false,
      containerType: "20DRY",
    },
  ],
  containersNumberInfo: null,
  productsInfo: [],
  userNote: null,
  shipdaNote: null,
  createdAt: "2022-08-01T00:48:19.000Z",
  updatedAt: "2022-08-01T01:19:18.000Z",
  acceptedAt: "2022-08-01",
  containDomesticFee: true,
  containOceanSurcharge: true,
  containLss: false,
  inlandType: null,
  containCustoms: false,
  unFixedCostInfo: null,
  quotationInfoForDownload: {
    etc: [
      {
        detail: "상해항 -> 인천신항",
        category: "ROUTE(구간)",
      },
      {
        detail: "고려해운(KMTC)",
        category: "LINER(선사)",
      },
      {
        detail: "2022년 02월 28일",
        category: "VALIDITY(운임 유효기간)",
      },
    ],
    total: "585,788",
    locale: "KR",
    comment:
      "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
    request: {
      fob: "FOB조건(상해항 -> 인천신항)",
      item: "test",
      load: "20DRY x 1대",
    },
    taxCost: [],
    currency: {
      usd: "1216.8",
      euro: "1353.48",
      yuan: "192.75",
    },
    localCost: [],
    otherCost: [],
    inlandCost: [],
    freightType: "FCL",
    serviceType: "general",
    adminContact: {
      name: "손석균",
      email: "max@ship-da.com",
      phone: "02-6956-7213",
    },
    domesticCost: [
      {
        cost: "130,000",
        note: "",
        unit: "CNTR(20DRY)",
        costC: "KRW",
        isVAT: false,
        shipda: "130,000",
        shipdaC: "KRW",
        category: "THC(터미널 화물 처리비)",
        quantity: "1",
      },
      {
        cost: "4,420",
        note: "",
        unit: "CNTR(20DRY)",
        costC: "KRW",
        isVAT: false,
        shipda: "4,420",
        shipdaC: "KRW",
        category: "WFG(부두사용료)",
        quantity: "1",
      },
      {
        cost: "35,000",
        note: "",
        unit: "CNTR(20DRY)",
        costC: "KRW",
        isVAT: false,
        shipda: "35,000",
        shipdaC: "KRW",
        category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
        quantity: "1",
      },
      {
        cost: "50,000",
        note: "",
        unit: "B/L",
        costC: "KRW",
        isVAT: true,
        shipda: "50,000",
        shipdaC: "KRW",
        category: "HANDLING CHARGE(포워더 대행 수수료)",
        quantity: "1",
      },
      {
        cost: "50,000",
        note: "",
        unit: "B/L",
        costC: "KRW",
        isVAT: true,
        shipda: "50,000",
        shipdaC: "KRW",
        category: "DOC FEE(서류 발급비)",
        quantity: "1",
      },
      {
        cost: "0",
        note: "",
        unit: "B/L",
        costC: "KRW",
        isVAT: true,
        shipda: "0",
        shipdaC: "KRW",
        category: "D/O FEE(화물인도지시서)",
        quantity: "1",
      },
    ],
    oceanFreight: [
      {
        cost: "0",
        note: "",
        unit: "CNTR(20DRY)",
        costC: "KRW",
        isVAT: false,
        shipda: "0",
        shipdaC: "USD",
        category: "OCEAN FREIGHT(해상운임)",
        quantity: "1",
      },
      {
        cost: "267,696",
        note: "",
        unit: "CNTR(20DRY)",
        costC: "KRW",
        isVAT: false,
        shipda: "220",
        shipdaC: "USD",
        category: "BAF & CAF(유류할증료&통화할증료)",
        quantity: "1",
      },
      {
        cost: "48,672",
        note: "",
        unit: "CNTR(20DRY)",
        costC: "KRW",
        isVAT: false,
        shipda: "40",
        shipdaC: "USD",
        category: "CRS(긴급비용할증료)",
        quantity: "1",
      },
    ],
  },
  exporterInfo: {
    personName: "수출자 담당자 테스트",
    companyName: "수출자 회사 테스트",
    personEmail: "exam@ax.sas",
    personPhone: "010",
  },
  totalCBM: null,
  totalWeight: null,
  wareHouseId: null,
  exportersCount: null,
  needFTACertificateAgency: false,
  FTACertificateFlag: false,
  needIORAgency: null,
  freeStorageDays: null,
  locale: "KR",
  receivingId: null,
  deletedAt: null,
  startPort: {
    id: 43,
    code: "CNSGH",
    codeMT: null,
    name: "상해항",
    nameC: "상하이항",
    nameEN: "Shanghai port",
    country: "CN",
    region: "Asia",
    type: "sea",
    order: 1,
    lat: 31.339001,
    lng: 121.660112,
    isOceanTicketOriginPort: true,
    isActiveOceanTicketPort: true,
  },
  startViaPort: null,
  endPort: {
    id: 336,
    code: "KRICN",
    codeMT: null,
    name: "인천신항",
    nameC: "인천신항",
    nameEN: "New Incheon port",
    country: "KR",
    region: "Asia",
    type: "sea",
    order: 6,
    lat: 37.344653,
    lng: 126.63528,
    isOceanTicketOriginPort: false,
    isActiveOceanTicketPort: false,
  },
  endViaPort: null,
  user: {
    id: 6686,
    accountId: "c09adbbb-070e-42a0-9bda-f82a57e98d4f",
    email: "egg_159@naver.com",
    invoiceEmail: null,
    name: "신지원",
    lastName: null,
    engName: null,
    phone: "01026453671",
    representativeName: null,
    address: null,
    type: "consignee",
    registerType: null,
    freightType: null,
    company: "테스트",
    engCompany: "iiii",
    senderName: null,
    senderPhone: null,
    senderAddress: null,
    senderDetailAddress: null,
    senderPostCode: null,
    version: 2,
    country: "KR",
    forwardingManagerId: 46,
    fullfillmentManagerId: 11,
    fullfillmentSalesManagerId: 7,
    BRN: "1",
    businessType: "person",
    isConfirm: false,
    comments: null,
    appliedFulfillmentAt: "2022-08-01T00:58:10.000Z",
    bizClass: "전자상거래업",
    bizType: "소매업",
    createdAt: "2022-07-27T05:18:36.000Z",
    updatedAt: "2022-09-05T02:31:45.000Z",
    deletedAt: null,
    lastLoginAt: "2022-09-05T02:31:46.000Z",
    brnAttachments: [
      {
        id: 2956,
        isBinded: true,
        isAdmin: false,
        userId: 6686,
        bucket: "sellernote-files-dev",
        key: "brn/45e8b3e6-c5ec-4f74-b752-fa1a758b81ee.pdf",
        name: "쉽다_견적서 (1).pdf",
        description: null,
        domain: "brn",
        targetId: 6686,
        subTargetId: null,
        metadata: null,
        createdAt: "2022-08-01T01:23:15.000Z",
        deletedAt: null,
      },
    ],
  },
  management: {
    id: 3703,
    bidId: 102894,
    BLType: "HBL",
    mBL: null,
    hBL: "WDFCGBF26982280L",
    cargMtNo: "22WDFCF698I07040012",
    voyageNumber: "2698",
    package: "312 GT",
    invoiceCbm: "13 CBM / 5000 kgs",
    prnm: "FITENSS RACK FITNESS BALL FITNESS BAR",
    unipassWarehouseInfo: null,
    arrivalDate: "2022-07-26",
    ETA: "2022-07-31",
    ETD: "2022-07-29",
    shipIMO: 9159335,
    shipName: "NEWGOLDENBRIDGE 5",
    scheduleChangeReason: null,
    detailStatus: null,
    initialNoProceedReason: null,
    noProceedReason: null,
    comments: [
      {
        id: "jws",
        date: "2022. 8. 1. 오전 9:49:31",
        comment: "지급유형 정보를 변경했습니다. (null -> pp)",
      },
      {
        id: "jws",
        date: "2022. 8. 1. 오전 9:49:36",
        comment: "해외 파트너/포워더를 Globelink Shanghai로 선택했습니다.",
      },
      {
        id: "geunee92",
        date: "2022. 9. 2. 오전 10:17:49",
        comment: "거래명세서(매입/관세사)이 추가되었습니다",
      },
    ],
    confirmFlag: false,
    customsPaymentFlag: false,
    documentMailingFlag: false,
    dueDate: null,
    deliveryType: null,
    parcelCompany: null,
    parcelNum: null,
    driverName: null,
    driverPhone: null,
    truckNum: null,
    forwardingManagerId: 46,
    fullfillmentManagerId: null,
    contactedAdminId: null,
    contactedAt: null,
    importDeclarationDate: null,
    remarketingDate: null,
    deliveryStartDate: null,
    inProgressDate: "2022-08-01T00:48:30.000Z",
    createdAt: "2022-08-01T00:48:19.000Z",
    updatedAt: "2022-09-13T04:14:54.000Z",
    ship: {
      IMO: 9159335,
      MMSI: 441953000,
      name: "HANSUNG INCHEON",
      shipType: 70,
      shipTypeName: "Container Ship",
      callSign: "D8BF",
      flag: "KR",
      length: 137,
      width: 20,
      builtYear: 1997,
      GRT: 5888,
      DWT: 4150,
      draught: 61,
      createdAt: "2021-06-29T08:09:28.000Z",
    },
  },
  quotationsUser: [
    {
      id: 2387,
      bidId: 102894,
      userId: 6686,
      managerId: null,
      quotationId: null,
      status: "succeed",
      startCountry: "CN",
      startPortId: 43,
      endCountry: "KR",
      endPortId: 336,
      endZoneId: null,
      incoterms: "FOB",
      freightType: "FCL",
      volumn: "",
      liner: "고려해운(KMTC)",
      linerId: 145,
      isTransit: false,
      leadtime: null,
      exchangeRate: "1216.80",
      totalPrice: 585788,
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          isVAT: false,
          amount: 1,
          atCost: false,
          currency: "USD",
          itemPrice: 0,
          unitPrice: 0,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          isVAT: false,
          amount: 1,
          atCost: false,
          currency: "USD",
          itemPrice: 267696,
          unitPrice: 220,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          isVAT: false,
          amount: 1,
          atCost: false,
          currency: "USD",
          itemPrice: 48672,
          unitPrice: 40,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      localFee: [],
      inlandFee: [],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          isVAT: false,
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 130000,
          unitPrice: 130000,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          isVAT: false,
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 4420,
          unitPrice: 4420,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          isVAT: false,
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 35000,
          unitPrice: 35000,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          isVAT: true,
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          isVAT: true,
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          isVAT: true,
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          itemUnitMeasurement: "B/L",
        },
      ],
      taxFee: [],
      otherFee: [],
      deletedAt: null,
      comment:
        "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
      acceptedAt: "2022-08-01T00:48:19.000Z",
      createdAt: "2022-08-01T00:48:19.000Z",
      expiredAt: "2022-02-28T00:00:00.000Z",
      fileKey: null,
      freightPaymentType: "pp",
      updateFlag: true,
    },
  ],
  wareHouse: null,
  zone: null,
  invoices: [
    {
      id: 333,
      bidId: 102894,
      invoiceType: "invoice",
      freightType: "FCL",
      recipient: "테스트",
      BRN: "1",
      voyageNumber: "NEWGOLDENBRIDGE 5 / 2698",
      package: "312 GT",
      cbm: "13 CBM / 5,000 kgs",
      rton: "13",
      arrivalDate: "2022-07-26",
      portOfLoading: "SHANGHAI PORT / CHINA",
      portOfDischarge: "NEW INCHEON PORT / KOREA",
      incoterms: "FOB",
      mode: "FCL",
      currency: " USD: 1216.8",
      totalPrice: "585,788",
      vatPrice: "10,000",
      finalPrice: "595,788",
      comment:
        "・창고보관료는 반입일로부터 일주일 간 무료입니다 <br />.・입금일을 기준으로 해당 월에 계산서 발행됩니다. 변경을 원하시면 담당 매니저님에게 연락 부탁드립니다.",
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "USD",
          itemPrice: "0",
          unitPrice: "0",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "USD",
          itemPrice: "267,696",
          unitPrice: "220",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "USD",
          itemPrice: "48,672",
          unitPrice: "40",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      localFee: [],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "130,000",
          unitPrice: "130,000",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "4,420",
          unitPrice: "4,420",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "35,000",
          unitPrice: "35,000",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          isVAT: true,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "50,000",
          unitPrice: "50,000",
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          isVAT: true,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "50,000",
          unitPrice: "50,000",
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          isVAT: true,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "0",
          unitPrice: "0",
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      depositDate: null,
      sendDate: null,
      sendDateExchangeRate: 1216.8,
      tempExchangeRate: 0,
      isSended: false,
      isCustomSended: false,
      isPaymentComplete: false,
      isIssued: false,
      isTemporary: false,
      createdAt: "2022-08-01T00:00:00.000Z",
      issuedInvoices: [
        {
          id: 258,
          bidId: 102894,
          invoiceId: 333,
          invoiceType: "invoice",
          issueInvoiceType: "taxation",
          issueDate: null,
          isIssued: false,
          recipient: "테스트",
          BRN: "1",
          totalPrice: 100000,
          vatPrice: 10000,
          finalPrice: 110000,
          depositDate: null,
          forwardingManagerId: 46,
          mgtKey: null,
          deletedAt: null,
          createdAt: "2022-08-01T01:24:52.000Z",
        },
        {
          id: 257,
          bidId: 102894,
          invoiceId: 333,
          invoiceType: "invoice",
          issueInvoiceType: "zeroTax",
          issueDate: null,
          isIssued: true,
          recipient: "테스트",
          BRN: "1",
          totalPrice: 485788,
          vatPrice: 0,
          finalPrice: 485788,
          depositDate: null,
          forwardingManagerId: 46,
          mgtKey: "a672dfa4e08dd04311c9",
          deletedAt: null,
          createdAt: "2022-08-01T01:24:52.000Z",
        },
      ],
    },
  ],
  attachments: [
    {
      id: 3186,
      isBinded: true,
      isAdmin: true,
      userId: 42,
      bucket: "sellernote-files-dev",
      key: "CI_PL/47d3fc8f-bcc3-4237-a6fa-e91afd87921a.pdf",
      name: "사업자등록증_셀러노트_0630.pdf",
      description: "123",
      domain: "CI_PL",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-09-13T01:06:30.000Z",
      deletedAt: null,
    },
    {
      id: 3181,
      isBinded: true,
      isAdmin: true,
      userId: 42,
      bucket: "sellernote-files-dev",
      key: "etc/8c1e02a8-1f03-41cd-b6ad-407fda01d753.pdf",
      name: "사업자등록증_셀러노트_0630.pdf",
      description: null,
      domain: "etc",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-09-13T00:56:37.000Z",
      deletedAt: null,
    },
    {
      id: 3126,
      isBinded: true,
      isAdmin: true,
      userId: 62,
      bucket: "sellernote-files-dev",
      key: "adminTemp/0782a958-9980-4093-8940-1031ff407e8b.pdf",
      name: "쉽다_견적서 (34).pdf",
      description: null,
      domain: "adminTemp",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-09-07T06:55:51.000Z",
      deletedAt: null,
    },
    {
      id: 3104,
      isBinded: true,
      isAdmin: true,
      userId: 42,
      bucket: "sellernote-files-dev",
      key: "CO/bd607ec1-6b5f-48ed-a16e-baf1af721ea3.pdf",
      name: "쉽다_견적서 - 2022-09-02T135118.911.pdf",
      description: null,
      domain: "CO",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-09-07T01:09:28.000Z",
      deletedAt: null,
    },
    {
      id: 3103,
      isBinded: true,
      isAdmin: true,
      userId: 42,
      bucket: "sellernote-files-dev",
      key: "etc/bb43d61a-0f29-4cd9-8c3a-dd9dc7d577a8.pdf",
      name: "쉽다_견적서 - 2022-09-02T135118.911.pdf",
      description: null,
      domain: "etc",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-09-07T01:09:00.000Z",
      deletedAt: null,
    },
    {
      id: 3102,
      isBinded: true,
      isAdmin: true,
      userId: 42,
      bucket: "sellernote-files-dev",
      key: "etc/fe435761-1b30-4634-8064-5ca005c52eeb.pdf",
      name: "쉽다_견적서 - 2022-09-02T135118.911.pdf",
      description: null,
      domain: "etc",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-09-07T01:08:41.000Z",
      deletedAt: null,
    },
    {
      id: 3099,
      isBinded: true,
      isAdmin: true,
      userId: 55,
      bucket: "sellernote-files-dev",
      key: "certificate/2b0317dd-6217-4a38-a063-a1b07e1acd03.pdf",
      name: "수입세금계산서_LAOYSD2206005.pdf",
      description: null,
      domain: "certificate",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-09-06T09:14:12.000Z",
      deletedAt: null,
    },
    {
      id: 3098,
      isBinded: true,
      isAdmin: true,
      userId: 55,
      bucket: "sellernote-files-dev",
      key: "certificate/478d01dc-9ba2-4015-bbc2-85d2bde14067.jpeg",
      name: "1_(56) (1) (1).jpeg",
      description: null,
      domain: "certificate",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-09-06T09:13:57.000Z",
      deletedAt: null,
    },
    {
      id: 3095,
      isBinded: true,
      isAdmin: true,
      userId: 55,
      bucket: "sellernote-files-dev",
      key: "etc/887af7b9-cf6a-4cff-a025-0b0d4840c1a5.pdf",
      name: "쉽다_거래명세서 - 2022-09-05T103545.401.pdf",
      description: null,
      domain: "etc",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-09-06T09:00:55.000Z",
      deletedAt: null,
    },
    {
      id: 3094,
      isBinded: true,
      isAdmin: true,
      userId: 55,
      bucket: "sellernote-files-dev",
      key: "etc/79a42dd2-cdbb-446c-aa5f-6a57d8b6fd20.pdf",
      name: "쉽다_견적서 - 2022-08-16T172627.045.pdf",
      description: null,
      domain: "etc",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-09-06T09:00:29.000Z",
      deletedAt: null,
    },
    {
      id: 3075,
      isBinded: true,
      isAdmin: true,
      userId: 42,
      bucket: "sellernote-files-dev",
      key: "etc/b7978e1e-70fc-4196-af90-bbcb5b80cb24.pdf",
      name: "#102871220803-0101.pdf",
      description: null,
      domain: "etc",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-09-05T05:40:11.000Z",
      deletedAt: null,
    },
    {
      id: 3073,
      isBinded: true,
      isAdmin: true,
      userId: 42,
      bucket: "sellernote-files-dev",
      key: "releaseRequest/776f025d-1c92-4482-abbd-75f3230ec2c3.pdf",
      name: "쉽다_견적서 - 2022-09-02T135118.911.pdf",
      description: null,
      domain: "releaseRequest",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-09-05T05:34:19.000Z",
      deletedAt: null,
    },
    {
      id: 3069,
      isBinded: true,
      isAdmin: true,
      userId: 42,
      bucket: "sellernote-files-dev",
      key: "BL/6fb1e2d2-a866-44a1-b78a-83545ac52fa0.pdf",
      name: "쉽다_거래명세서 - 2022-08-25T131026.730.pdf",
      description: "123123",
      domain: "BL",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-09-02T07:48:58.000Z",
      deletedAt: null,
    },
    {
      id: 3068,
      isBinded: true,
      isAdmin: true,
      userId: 42,
      bucket: "sellernote-files-dev",
      key: "BL/c4f037fa-ce17-4407-a96f-762f25102fa0.pdf",
      name: "쉽다_견적서 - 2022-09-02T135118.911.pdf",
      description: "123123",
      domain: "BL",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-09-02T07:48:58.000Z",
      deletedAt: null,
    },
    {
      id: 2955,
      isBinded: true,
      isAdmin: true,
      userId: 67,
      bucket: "sellernote-files-dev",
      key: "invoice/1628927e-b86e-45b1-86b8-e13223daf88a.pdf",
      name: "#102894_invoice.pdf",
      description: null,
      domain: "invoice",
      targetId: 102894,
      subTargetId: 333,
      metadata: null,
      createdAt: "2022-08-01T01:22:34.000Z",
      deletedAt: null,
    },
    {
      id: 2954,
      isBinded: true,
      isAdmin: true,
      userId: 67,
      bucket: "sellernote-files-dev",
      key: "CI_PL/4fc9af31-8821-4f9b-bb46-37053ea517d3.pdf",
      name: "쉽다_거래명세서.pdf",
      description: "132123123",
      domain: "CI_PL",
      targetId: 102894,
      subTargetId: null,
      metadata: null,
      createdAt: "2022-08-01T01:20:38.000Z",
      deletedAt: null,
    },
  ],
  partnerManagement: {
    id: 2326,
    bidId: 102894,
    domesticPartnerCompanyId: null,
    domesticPartnerManagerIds: [],
    sentEmailToDomesticManagers: false,
    isDomesticPartnerPaymentComplete: false,
    domesticWithdrawalRequestId: null,
    domesticDepositReceiptId: null,
    foreignPartnerCompanyId: 952,
    foreignPartnerManagerIds: [4991],
    sentEmailToForeignManagers: true,
    isForeignPartnerPaymentComplete: false,
    isForeignWithdrawalTarget: false,
    foreignWithdrawalRequestId: null,
    foreignDepositReceiptId: null,
    customsPartnerCompanyId: null,
    customsPartnerManagerId: null,
    isCustomsPartnerPaymentComplete: false,
    customsClearanceStartFlag: false,
    dutyWithdrawalRequestId: null,
    dutyDepositReceiptId: null,
    customsWithdrawalRequestId: null,
    customsDepositReceiptId: null,
    inlandPartnerCompanyId: null,
    isInlandPartnerPaymentComplete: false,
    inlandWithdrawalRequestId: null,
    inlandDepositReceiptId: null,
    warehousePartnerCompanyId: null,
    isWarehousePartnerPaymentComplete: false,
    wareHouseWithdrawalRequestId: null,
    wareHouseDepositReceiptId: null,
    shippingPartnerCompanyId: null,
    isShippingPartnerPaymentComplete: false,
    shippingWithdrawalRequestId: null,
    shippingDepositReceiptId: null,
    etcPartnerCompanyId: null,
    isEtcPartnerPaymentComplete: false,
    etcWithdrawalRequestId: null,
    etcDepositReceiptId: null,
    etc2PartnerCompanyId: null,
    isEtc2PartnerPaymentComplete: false,
    etc2WithdrawalRequestId: null,
    etc2DepositReceiptId: null,
    foreignPartner: {
      id: 952,
      country: "CN",
      language: "en",
      name: "Globelink Shanghai",
      email: "",
      businessArea: "foreign",
      transportMode: "ALL",
      needsPlainTextEmail: true,
      settlementFlag: false,
      bankName: "",
      accountNumber: "",
      invoiceIssueFlag: false,
      BRNNumber: "",
      createdAt: "2021-07-29T05:09:58.000Z",
      isDel: false,
      deletedAt: null,
      BRNId: null,
      createUserId: null,
    },
    domesticPartner: null,
    customsPartner: null,
    inlandPartner: null,
    warehousePartner: null,
    shippingPartner: null,
    etcPartner: null,
    etc2Partner: null,
    domesticDepositReceipt: null,
    foreignDepositReceipt: null,
    dutyDepositReceipt: null,
    customsDepositReceipt: null,
    inlandDepositReceipt: null,
    wareHouseDepositReceipt: null,
    shippingDepositReceipt: null,
    etcDepositReceipt: null,
    etc2DepositReceipt: null,
    domesticRequest: null,
    foreignRequest: null,
    dutyRequest: null,
    customsRequest: null,
    inlandRequest: null,
    wareHouseRequest: null,
    shippingRequest: null,
    etcRequest: null,
    etc2Request: null,
  },
  bidInvoice: {
    id: 333,
    bidId: 102894,
    invoiceType: "invoice",
    freightType: "FCL",
    recipient: "테스트",
    BRN: "1",
    voyageNumber: "NEWGOLDENBRIDGE 5 / 2698",
    package: "312 GT",
    cbm: "13 CBM / 5,000 kgs",
    rton: "13",
    arrivalDate: "2022-07-26",
    portOfLoading: "SHANGHAI PORT / CHINA",
    portOfDischarge: "NEW INCHEON PORT / KOREA",
    incoterms: "FOB",
    mode: "FCL",
    currency: " USD: 1216.8",
    totalPrice: "585,788",
    vatPrice: "10,000",
    finalPrice: "595,788",
    name: "조형근",
    updatedAt: "2022-08-29",
    comment:
      "・창고보관료는 반입일로부터 일주일 간 무료입니다 <br />.・입금일을 기준으로 해당 월에 계산서 발행됩니다. 변경을 원하시면 담당 매니저님에게 연락 부탁드립니다.",
    freightFee: [
      {
        key: "oceanFreight20DRY",
        item: "OCEAN FREIGHT(해상운임)",
        note: "",
        isVAT: false,
        amount: "1",
        atCost: false,
        currency: "USD",
        itemPrice: "0",
        unitPrice: "0",
        itemUnitMeasurement: "CNTR(20DRY)",
      },
      {
        key: "bafCaf20DRY",
        item: "BAF & CAF(유류할증료&통화할증료)",
        note: "",
        isVAT: false,
        amount: "1",
        atCost: false,
        currency: "USD",
        itemPrice: "267,696",
        unitPrice: "220",
        itemUnitMeasurement: "CNTR(20DRY)",
      },
      {
        key: "totalAdditionalFee20DRY",
        item: "CRS(긴급비용할증료)",
        note: "",
        isVAT: false,
        amount: "1",
        atCost: false,
        currency: "USD",
        itemPrice: "48,672",
        unitPrice: "40",
        itemUnitMeasurement: "CNTR(20DRY)",
      },
    ],
    localFee: [],
    domesticFee: [
      {
        key: "thc20DRY",
        item: "THC(터미널 화물 처리비)",
        note: "",
        isVAT: false,
        amount: "1",
        atCost: false,
        currency: "KRW",
        itemPrice: "130,000",
        unitPrice: "130,000",
        itemUnitMeasurement: "CNTR(20DRY)",
      },
      {
        key: "wfg20DRY",
        item: "WFG(부두사용료)",
        note: "",
        isVAT: false,
        amount: "1",
        atCost: false,
        currency: "KRW",
        itemPrice: "4,420",
        unitPrice: "4,420",
        itemUnitMeasurement: "CNTR(20DRY)",
      },
      {
        key: "ccc20DRY",
        item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
        note: "",
        isVAT: false,
        amount: "1",
        atCost: false,
        currency: "KRW",
        itemPrice: "35,000",
        unitPrice: "35,000",
        itemUnitMeasurement: "CNTR(20DRY)",
      },
      {
        key: "hc",
        item: "HANDLING CHARGE(포워더 대행 수수료)",
        note: "",
        isVAT: true,
        amount: "1",
        atCost: false,
        currency: "KRW",
        itemPrice: "50,000",
        unitPrice: "50,000",
        itemUnitMeasurement: "B/L",
      },
      {
        key: "docFee",
        item: "DOC FEE(서류 발급비)",
        note: "",
        isVAT: true,
        amount: "1",
        atCost: false,
        currency: "KRW",
        itemPrice: "50,000",
        unitPrice: "50,000",
        itemUnitMeasurement: "B/L",
      },
      {
        key: "doFee",
        item: "D/O FEE(화물인도지시서)",
        note: "",
        isVAT: true,
        amount: "1",
        atCost: false,
        currency: "KRW",
        itemPrice: "0",
        unitPrice: "0",
        itemUnitMeasurement: "B/L",
      },
    ],
    inlandFee: [],
    taxFee: [],
    otherFee: [],
    depositDate: "2022-09-19",
    sendDate: null,
    sendDateExchangeRate: 1216.8,
    tempExchangeRate: 0,
    isSended: false,
    isCustomSended: false,
    isPaymentComplete: false,
    isIssued: false,
    isTemporary: false,
    createdAt: "2022-08-01T00:00:00.000Z",
    issuedInvoices: [
      {
        id: 258,
        bidId: 102894,
        invoiceId: 333,
        invoiceType: "invoice",
        issueInvoiceType: "taxation",
        issueDate: null,
        isIssued: false,
        recipient: "테스트",
        BRN: "1",
        totalPrice: 100000,
        vatPrice: 10000,
        finalPrice: 110000,
        depositDate: null,
        forwardingManagerId: 46,
        mgtKey: null,
        deletedAt: null,
        createdAt: "2022-08-01T01:24:52.000Z",
      },
      {
        id: 257,
        bidId: 102894,
        invoiceId: 333,
        invoiceType: "invoice",
        issueInvoiceType: "zeroTax",
        issueDate: null,
        isIssued: true,
        recipient: "테스트",
        BRN: "1",
        totalPrice: 485788,
        vatPrice: 0,
        finalPrice: 485788,
        depositDate: null,
        forwardingManagerId: 46,
        mgtKey: "a672dfa4e08dd04311c9",
        deletedAt: null,
        createdAt: "2022-08-01T01:24:52.000Z",
      },
    ],
  },
  refundInvoice: null,
  warehouseInvoice: [
    {
      id: 333,
      bidId: 102894,
      invoiceType: "warehouseInvoice",
      freightType: "FCL",
      recipient: "테스트",
      BRN: "1",
      voyageNumber: "NEWGOLDENBRIDGE 5 / 2698",
      package: "312 GT",
      cbm: "13 CBM / 5,000 kgs",
      rton: "13",
      arrivalDate: "2022-07-26",
      portOfLoading: "SHANGHAI PORT / CHINA",
      portOfDischarge: "NEW INCHEON PORT / KOREA",
      incoterms: "FOB",
      mode: "FCL",
      currency: " USD: 1216.8",
      totalPrice: "585,788",
      vatPrice: "10,000",
      finalPrice: "595,788",
      name: "조형근",
      updatedAt: "2022-08-29",
      comment:
        "・창고보관료는 반입일로부터 일주일 간 무료입니다 <br />.・입금일을 기준으로 해당 월에 계산서 발행됩니다. 변경을 원하시면 담당 매니저님에게 연락 부탁드립니다.",
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "USD",
          itemPrice: "0",
          unitPrice: "0",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "USD",
          itemPrice: "267,696",
          unitPrice: "220",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "USD",
          itemPrice: "48,672",
          unitPrice: "40",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      localFee: [],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "130,000",
          unitPrice: "130,000",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "4,420",
          unitPrice: "4,420",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "35,000",
          unitPrice: "35,000",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          isVAT: true,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "50,000",
          unitPrice: "50,000",
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          isVAT: true,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "50,000",
          unitPrice: "50,000",
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          isVAT: true,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "0",
          unitPrice: "0",
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      depositDate: null,
      sendDate: null,
      sendDateExchangeRate: 1216.8,
      tempExchangeRate: 0,
      isSended: false,
      isCustomSended: false,
      isPaymentComplete: false,
      isIssued: false,
      isTemporary: false,
      createdAt: "2022-08-01T00:00:00.000Z",
      issuedInvoices: [
        {
          id: 258,
          bidId: 102894,
          invoiceId: 333,
          invoiceType: "warehouseInvoice",
          issueInvoiceType: "taxation",
          issueDate: null,
          isIssued: false,
          recipient: "테스트",
          BRN: "1",
          totalPrice: 100000,
          vatPrice: 10000,
          finalPrice: 110000,
          depositDate: null,
          forwardingManagerId: 46,
          mgtKey: null,
          deletedAt: null,
          createdAt: "2022-08-01T01:24:52.000Z",
        },
        {
          id: 257,
          bidId: 102894,
          invoiceId: 333,
          invoiceType: "warehouseInvoice",
          issueInvoiceType: "zeroTax",
          issueDate: null,
          isIssued: true,
          recipient: "테스트",
          BRN: "1",
          totalPrice: 485788,
          vatPrice: 0,
          finalPrice: 485788,
          depositDate: null,
          forwardingManagerId: 46,
          mgtKey: "a672dfa4e08dd04311c9",
          deletedAt: null,
          createdAt: "2022-08-01T01:24:52.000Z",
        },
      ],
    },
    {
      id: 3323,
      bidId: 102894,
      invoiceType: "warehouseInvoice",
      freightType: "FCL",
      recipient: "테스트",
      BRN: "1",
      voyageNumber: "NEWGOLDENBRIDGE 5 / 2698",
      package: "312 GT",
      cbm: "13 CBM / 5,000 kgs",
      rton: "13",
      arrivalDate: "2022-07-26",
      portOfLoading: "SHANGHAI PORT / CHINA",
      portOfDischarge: "NEW INCHEON PORT / KOREA",
      incoterms: "FOB",
      mode: "FCL",
      currency: " USD: 1216.8",
      totalPrice: "585,788",
      vatPrice: "10,000",
      finalPrice: "595,788",
      name: "조형근",
      updatedAt: "2022-08-29",
      comment:
        "・창고보관료는 반입일로부터 일주일 간 무료입니다 <br />.・입금일을 기준으로 해당 월에 계산서 발행됩니다. 변경을 원하시면 담당 매니저님에게 연락 부탁드립니다.",
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "USD",
          itemPrice: "0",
          unitPrice: "0",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "USD",
          itemPrice: "267,696",
          unitPrice: "220",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "USD",
          itemPrice: "48,672",
          unitPrice: "40",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      localFee: [],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "130,000",
          unitPrice: "130,000",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "4,420",
          unitPrice: "4,420",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          isVAT: false,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "35,000",
          unitPrice: "35,000",
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          isVAT: true,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "50,000",
          unitPrice: "50,000",
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          isVAT: true,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "50,000",
          unitPrice: "50,000",
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          isVAT: true,
          amount: "1",
          atCost: false,
          currency: "KRW",
          itemPrice: "0",
          unitPrice: "0",
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      depositDate: null,
      sendDate: null,
      sendDateExchangeRate: 1216.8,
      tempExchangeRate: 0,
      isSended: false,
      isCustomSended: false,
      isPaymentComplete: false,
      isIssued: false,
      isTemporary: false,
      createdAt: "2022-08-01T00:00:00.000Z",
      issuedInvoices: [
        {
          id: 251,
          bidId: 102894,
          invoiceId: 333,
          invoiceType: "warehouseInvoice",
          issueInvoiceType: "taxation",
          issueDate: null,
          isIssued: false,
          recipient: "테스트",
          BRN: "1",
          totalPrice: 100000,
          vatPrice: 10000,
          finalPrice: 110000,
          depositDate: null,
          forwardingManagerId: 46,
          mgtKey: null,
          deletedAt: null,
          createdAt: "2022-08-01T01:24:52.000Z",
        },
        {
          id: 280,
          bidId: 102894,
          invoiceId: 333,
          invoiceType: "warehouseInvoice",
          issueInvoiceType: "zeroTax",
          issueDate: null,
          isIssued: true,
          recipient: "테스트",
          BRN: "1",
          totalPrice: 485788,
          vatPrice: 0,
          finalPrice: 485788,
          depositDate: null,
          forwardingManagerId: 46,
          mgtKey: "a672dfa4e08dd04311c9",
          deletedAt: null,
          createdAt: "2022-08-01T01:24:52.000Z",
        },
      ],
    },
  ],
  etcDepositInvoice: [],
  attachmentInfo: {
    CI_PL: [
      {
        id: 3186,
        bucket: "sellernote-files-dev",
        key: "CI_PL/47d3fc8f-bcc3-4237-a6fa-e91afd87921a.pdf",
        isAdmin: true,
        name: "사업자등록증_셀러노트_0630.pdf",
      },
      {
        id: 2954,
        bucket: "sellernote-files-dev",
        key: "CI_PL/4fc9af31-8821-4f9b-bb46-37053ea517d3.pdf",
        isAdmin: true,
        name: "쉽다_거래명세서.pdf",
      },
    ],
    etc: [
      {
        id: 3181,
        bucket: "sellernote-files-dev",
        key: "etc/8c1e02a8-1f03-41cd-b6ad-407fda01d753.pdf",
        isAdmin: true,
        name: "사업자등록증_셀러노트_0630.pdf",
      },
      {
        id: 3103,
        bucket: "sellernote-files-dev",
        key: "etc/bb43d61a-0f29-4cd9-8c3a-dd9dc7d577a8.pdf",
        isAdmin: true,
        name: "쉽다_견적서 - 2022-09-02T135118.911.pdf",
      },
      {
        id: 3102,
        bucket: "sellernote-files-dev",
        key: "etc/fe435761-1b30-4634-8064-5ca005c52eeb.pdf",
        isAdmin: true,
        name: "쉽다_견적서 - 2022-09-02T135118.911.pdf",
      },
      {
        id: 3095,
        bucket: "sellernote-files-dev",
        key: "etc/887af7b9-cf6a-4cff-a025-0b0d4840c1a5.pdf",
        isAdmin: true,
        name: "쉽다_거래명세서 - 2022-09-05T103545.401.pdf",
      },
      {
        id: 3094,
        bucket: "sellernote-files-dev",
        key: "etc/79a42dd2-cdbb-446c-aa5f-6a57d8b6fd20.pdf",
        isAdmin: true,
        name: "쉽다_견적서 - 2022-08-16T172627.045.pdf",
      },
      {
        id: 3075,
        bucket: "sellernote-files-dev",
        key: "etc/b7978e1e-70fc-4196-af90-bbcb5b80cb24.pdf",
        isAdmin: true,
        name: "#102871220803-0101.pdf",
      },
    ],
    adminTemp: [
      {
        id: 3126,
        bucket: "sellernote-files-dev",
        key: "adminTemp/0782a958-9980-4093-8940-1031ff407e8b.pdf",
        isAdmin: true,
        name: "쉽다_견적서 (34).pdf",
      },
    ],
    CO: [
      {
        id: 3104,
        bucket: "sellernote-files-dev",
        key: "CO/bd607ec1-6b5f-48ed-a16e-baf1af721ea3.pdf",
        isAdmin: true,
        name: "쉽다_견적서 - 2022-09-02T135118.911.pdf",
      },
    ],
    certificate: [
      {
        id: 3099,
        bucket: "sellernote-files-dev",
        key: "certificate/2b0317dd-6217-4a38-a063-a1b07e1acd03.pdf",
        isAdmin: true,
        name: "수입세금계산서_LAOYSD2206005.pdf",
      },
      {
        id: 3098,
        bucket: "sellernote-files-dev",
        key: "certificate/478d01dc-9ba2-4015-bbc2-85d2bde14067.jpeg",
        isAdmin: true,
        name: "1_(56) (1) (1).jpeg",
      },
    ],
    releaseRequest: [
      {
        id: 3073,
        bucket: "sellernote-files-dev",
        key: "releaseRequest/776f025d-1c92-4482-abbd-75f3230ec2c3.pdf",
        isAdmin: true,
        name: "쉽다_견적서 - 2022-09-02T135118.911.pdf",
      },
    ],
    BL: [
      {
        id: 3069,
        bucket: "sellernote-files-dev",
        key: "BL/6fb1e2d2-a866-44a1-b78a-83545ac52fa0.pdf",
        isAdmin: true,
        name: "쉽다_거래명세서 - 2022-08-25T131026.730.pdf",
      },
      {
        id: 3068,
        bucket: "sellernote-files-dev",
        key: "BL/c4f037fa-ce17-4407-a96f-762f25102fa0.pdf",
        isAdmin: true,
        name: "쉽다_견적서 - 2022-09-02T135118.911.pdf",
      },
    ],
    invoice: [
      {
        id: 2955,
        bucket: "sellernote-files-dev",
        key: "invoice/1628927e-b86e-45b1-86b8-e13223daf88a.pdf",
        isAdmin: true,
        name: "#102894_invoice.pdf",
      },
    ],
    brn: [
      {
        id: 2956,
        bucket: "sellernote-files-dev",
        key: "brn/45e8b3e6-c5ec-4f74-b752-fa1a758b81ee.pdf",
        isAdmin: false,
        name: "쉽다_견적서 (1).pdf",
      },
    ],
    parcelRequest: [
      {
        id: 3088,
        bucket: "sellernote-files-dev",
        key: "parcelRequest/7664eea6-c578-4a48-a986-aba493062d6e.pdf",
        isAdmin: true,
        name: "#102894220906-0301.pdf",
      },
    ],
  },
  purchaseAttachments: {
    domesticPartnerInvoices: [],
    foreignPartnerInvoices: [],
    customsPartnerInvoices: [
      {
        id: 3067,
        isBinded: true,
        isAdmin: true,
        userId: 42,
        bucket: "sellernote-files-dev",
        key: "invoice/3a343de2-b261-40c8-a2d6-5eaf442558d6.pdf",
        name: "쉽다_거래명세서 - 2022-08-25T131026.730.pdf",
        description: "123123123",
        domain: "customsPartnerInvoice",
        targetId: 102894,
        subTargetId: null,
        metadata: null,
        createdAt: "2022-09-02T01:17:48.000Z",
        deletedAt: null,
      },
    ],
    customsInvoices: [],
    inlandPartnerInvoices: [],
    warehousePartnerInvoices: [],
    shippingPartnerInvoices: [],
    etcPartnerInvoices: [],
    etc2PartnerInvoices: [],
  },
  profitManagementInfo: {
    salesManagementInfo: {
      invoice: {
        price: 585788,
        issueFlag: false,
      },
      warehouseReceipt: [
        {
          price: 12345,
          issueFlag: false,
        },
        {
          price: 12346,
          issueFlag: false,
        },
        {
          price: 12347,
          issueFlag: false,
        },
      ],
      etcDeposit: [
        {
          price: 123454,
          issueFlag: false,
        },
        {
          price: 123464,
          issueFlag: false,
        },
        {
          price: 123474,
          issueFlag: false,
        },
      ],
    },
    purchaseManagementInfo: {
      foreign: [
        {
          price: 12345,
          withdrawalFlag: false,
        },
        {
          price: 12346,
          withdrawalFlag: false,
        },
        {
          price: 12347,
          withdrawalFlag: false,
        },
      ],
      customs: [
        {
          price: 12345,
          withdrawalFlag: false,
        },
        {
          price: 12346,
          withdrawalFlag: false,
        },
        {
          price: 12347,
          withdrawalFlag: false,
        },
      ],
    },
  },
};

export const GET_TRELLO_TRACKING_SHIP_DATA: GET_BID_TRACKING_SHIP_RES = {
  startPort: {
    id: 13,
    lat: 22.3922,
    lng: -97.9387,
    code: "KRPTK",
    name: "아무 테스트 출발항",
    nameC: null,
    nameEN: "testStartPort",
  },
  endPort: {
    id: 37,
    lat: 35.1017,
    lng: 129.03,
    code: "CNWEI",
    name: "아무 테스트 도착항",
    nameC: "웨이하이항",
    nameEN: "testEndPort",
  },
  pin: { lat: 7.0079, lng: -80.2078, heading: 1, showVesselIcon: false },
  routes: [],
  waypoints: [
    {
      lat: 33.74,
      lon: -118.27,
      locode: "USLAX",
      name: "USLAX",
      country: "US",
      locType: "GENERAL",
    },
    {
      lat: 33.75,
      lon: -118.21,
      locode: "USLGB",
      name: "USLGB",
      country: "US",
      locType: "GENERAL",
    },
  ],
};
